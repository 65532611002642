@charset "utf-8";

/* =================================
  cv banner
================================= */
.cvItem--01 {
  padding: 65px 1% 60px;
  position: relative;
  &.orange {
    background: url(../img/bg_cvItem01.jpg) no-repeat center center;
    background-size: cover;
    .telWrap {
      background: #fff;
      .num {
        color: $color-orange;
        &::before {
          @include icon-base(36px,36px);
          background: url(../img/icon/icon_tel_orange.png) no-repeat center center;
          background-size: contain;
          margin: 5px 10px 0 0;
          animation: vibrate02 2.5s infinite;
        }
      }
      .receptionTime {
        color: $color-orange;
      }
      @include mq-desktop {
        &:hover {
          background: $color-theme;
          .num {
            &::before {
              background: url(../img/icon/icon_tel_white.png) no-repeat center center;
              background-size: contain;
              animation: vibrate .5s 1;
            }
          }
        }
      }
    }
    .mailWrap {
      a {
        color: #fff;
        background: $color-form-red;
        &::before {
          @include icon-base(34px,26px);
          background: url(../img/icon/icon_mail_white.png) no-repeat center center;
          background-size: contain;
          margin-right: 13px;
        }
        @include mq-desktop {
          &:hover {
            background: $color-theme;
            &::before {
              animation: vibrate .5s 1;
            }
          }
        }
      }
    }
  }
  &.blue {
    background: url(../img/bg_cvItem02.jpg) no-repeat center center;
    background-size: cover;
    .telWrap {
      background: #e5f6fe;
      .num {
        color: $font-color-base;
      }
      .receptionTime {
        color: $font-color-base;
      }
      @include mq-desktop {
        &:hover {
          background: $color-orange;
        }
      }
    }
    .mailWrap {
      a {
        color: $color-theme;
        background: #fff;
        @include mq-desktop {
          &:hover {
            background: $color-orange;
          }
        }
      }
    }
  }
  &__inner {
    max-width: 694px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    .cvTtl {
      font-size: 3.6rem;
      font-weight: 900;
      color: #fff;
      text-align: center;
    }
    .text {
      font-size: 1.6rem;
      font-weight: 600;
      text-align: center;
      color: #fff;
      line-height: 1.875;
      margin: 20px 0 0;
    }
    .telWrap {
      text-align: center;
      padding: 10px 35px 10px 23px;
      border-radius: 3px;
      .num {
        @extend %font-round;
        font-size: 3.5rem;
        font-weight: 900;
      }
      .receptionTime {
        font-size: 1.5rem;
      }
      @include mq-desktop {
        &:hover {
          .num {
            color: #fff;
          }
          .receptionTime {
            color: #fff;
          }
        }
      }
    }
    .mailWrap {
      a {
        font-size: 2.4rem;
        font-weight: 900;
        display: block;
        padding: 27px 23px;
        border-radius: 3px;
      }
      @include mq-desktop {
        &:hover {
          a {
            color: #fff;
          }
        }
      }
    }
  }
}
.cvItem--02 {
  background: url(../img/bg_cvItem03.jpg) no-repeat center center;
  background-size: cover;
  padding: 65px 1% 60px;
  &__inner {
    .cvTtl {
      font-size: 3.6rem;
      font-weight: 900;
      color: #fff;
      text-align: center;
    }
    .text {
      font-size: 1.6rem;
      font-weight: 600;
      text-align: center;
      color: #fff;
      line-height: 1.875;
      margin: 20px 0 0;
    }
    .mailWrap {
      max-width: 476px;
      margin: 0 auto;
      a {
        font-size: 2.6rem;
        font-weight: 900;
        color: #fff;
        display: block;
        padding: 27px 23px;
        background: $color-form-red;
        border-radius: 3px;
        &::before {
          @include icon-base(34px,26px);
          background: url(../img/icon/icon_mail_white.png) no-repeat center center;
          background-size: contain;
          margin-right: 13px;
        }
        @include mq-desktop {
          &:hover {
            background: #fff;
            color: $color-orange;
            opacity: 1;
            &::before {
              background: url(../img/icon/icon_mail_orange.png) no-repeat center center;
              background-size: contain;
              margin-right: 13px;
              animation: vibrate .5s 1;
            }
          }
        }
      }
    }
  }
}

/* =================================
  toppage
================================= */
.topPage {
  //トップページ共通
  .ttl--topMain {
    text-align: center;
    padding: 58px 1% 70px;
    &__ttl {
      @extend %font-round;
      font-size: 3.2rem;
      font-weight: 900;
      line-height: 1.25;
      &.web {
        &::before {
          @include icon-base(30px,31px);
          background: url(../img/icon/icon_web_blue.png) no-repeat center center;
          background-size: contain;
          margin: 0 20px 5px 0;
        }
      }
      &.media {
        &::before {
          @include icon-base(30px,23px);
          background: url(../img/icon/icon_media_black.png) no-repeat center center;
          background-size: contain;
          margin: 0 15px 0 0;
        }
      }
      &.info {
        &::before {
          @include icon-base(21px,30px);
          background: url(../img/icon/icon_info_blue.png) no-repeat center center;
          background-size: contain;
          margin: 0 17px 0 0;
        }
      }
      &.service {
        &::before {
          @include icon-base(21px,30px);
          background: url(../img/icon/icon_service_black.png) no-repeat center center;
          background-size: contain;
          margin: 0 16px 0 0;
        }
      }
      &.works {
        &::before {
          @include icon-base(30px,21px);
          background: url(../img/icon/icon_works_white.png) no-repeat center center;
          background-size: contain;
          margin: 0 12px 0 0;
        }
      }
      &.column {
        &::before {
          @include icon-base(26px,30px);
          background: url(../img/icon/icon_column_blue.png) no-repeat center center;
          background-size: contain;
          margin: 0 14px 0 0;
        }
      }
      &.outline {
        &::before {
          @include icon-base(28px,30px);
          background: url(../img/icon/icon_outline_blue.png) no-repeat center center;
          background-size: contain;
          margin: 0 5px 0 0;
        }
      }
      &.contact {
        &::before {
          @include icon-base(29px,22px);
          background: url(../img/icon/icon_contact_blue.png) no-repeat center center;
          background-size: contain;
          margin: 0 6px 0 0;
        }
      }
    }
    &__text {
      font-size: 1.7rem;
      font-weight: 900;
      margin-top: 25px;
    }
    &.blue {
      .ttl--topMain__ttl {
        color: $color-theme;
      }
    }
    &.black {
      .ttl--topMain__ttl {
        color: $font-color-base;
      }
    }
    &.white {
      .ttl--topMain__ttl,.ttl--topMain__text {
        color: #fff;
      }
    }
  }
  .mv {
    background: url(../img/mv_bg.jpg) no-repeat center center;
    background-size: cover;
    padding: 148px 1% 130px;
    position: relative;
    &__inner {
      max-width: 824px;
      margin: 0 auto;
      .text--catch {
        text-align: center;
        position: relative;
        &__main {
          margin-top: 108px;
        }
        &__sub {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      .telWrap {
        font-weight: 900;
        background: #fff;
        border-radius: 50px;
        padding: 30px 38px 30px 43px;
        margin: 60px 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include animation-base;
        .text {
          font-size: 2rem;
          font-weight: 700;
          &::before {
            content: "";
            width: 27px;
            height: 27px;
            display: inline-block;
            background: url(../img/icon/icon_tel_black.png) no-repeat center center;
            background-size: contain;
            vertical-align: middle;
            margin: 0 22px 0 0;
          }
        }
        .numWrap {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          line-height: 1;
          .num {
            @extend %font-round;
            font-size: 3.6rem;
            color: $color-theme;
            margin-right: 30px;
          }
          .receptionTime {
            font-size: 1.2rem;
          }
        }
        @include mq-desktop {
          &:hover {
            background: $color-red;
            color: #fff;
            .num {
              color: #fff!important;
            }
          }
        }
      }
    }
    .scrollItem {
      position: absolute;
      right: 25px;
      bottom: 110px;
      .text {
        font-size: 1.4rem;
        font-weight: 900;
        color: #fff;
        writing-mode: vertical-rl;
        padding-left: 15px;
      }
      &::after {
        content: "";
        width: 2px;
        height: 0;
        display: inline-block;
        background: #fff;
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-50%);
        animation: scroll 2s infinite ease-in-out;
      }
    }
  }
  .topSec {
    &.webConsul {
      background: #fff;
      .webConsul__cont {
        background: url(../img/bg_webConsul_pc.jpg) no-repeat top center;
        background-size: cover;
        min-height: 380px;
        .textWrap {
          background: #fff;
          width: 55%;
          margin: 0 0 0 auto;
          padding: 30px 40px 50px;
          position: relative;
          &::after {
            content: "";
            width: calc(100% - 80px);
            height: 2px;
            display: block;
            background: $color-theme;
            position: absolute;
            top: 45px;
            left: 40px;
          }
          &__ttl {
            font-size: 2.7rem;
            color: $color-theme;
            margin: 0 0 25px;
            padding: 0 30px 0 0;
            display: inline-block;
            background: #fff;
            position: relative;
            z-index: 1;
          }
          &__text {
            font-size: 1.6rem;
            line-height: 2.125;
            color: $font-color-light;
          }
        }
      }
    }
    &.media {
      background: url(../img/bg_media.jpg) no-repeat center center;
      background-size: cover;
      .media__cont {
        .mediaList {
          &__item {
            padding: 0 0 140px 70px;
            position: relative;
            .topItem {
              width: calc(100% - 350px);
              position: absolute;
              right: 0;
              bottom: 0;
              img {
                margin: 0 0 0 auto;
              }
            }
            .botItem {
              max-width: 410px;
              .name {
                font-size: 3.4rem;
                font-weight: 900;
                color: $color-theme;
              }
              .text {
                line-height: 1.75;
                margin: 25px 0 0;
              }
              .btn {
                margin: 65px 0 0;
                display: block;
              }
            }
          }
          &.slick-initialized {
            .mediaList__item {
              padding-top: 80px;
            }
          }
          .slick-dots li.slick-active button:before {
            background: $color-theme;
          }
        }
      }
    }
    &.info {
      .infoList {
        max-width: 925px;
        margin: 0 auto;
        padding: 0 65px 68px;
        &__item {
          font-weight: 900;
          border: 1px solid #d7dcdd;
          border-radius: 3px;
          padding: 20px 22px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          position: relative;
          &::after {
            content: "＞";
            color: $color-green;
            position: absolute;
            top: 50%;
            right: 23px;
            transform: translateY(-50%);
          }
          .head {
            display: flex;
            align-items: center;
            margin-right: 18px;
            .day {
              font-size: 1.4rem;
              margin-right: 24px;
            }
            .tag {
              font-size: 1.2rem;
              line-height: 1;
              color: #fff;
              padding: 5px 18px;
              border-radius: 3px;
              &.blue {
                background: #267ea7;
              }
              &.green {
                background: $color-green;
              }
            }
          }
          & + .infoList__item {
            margin-top: 10px;
          }
        }
      }
    }
    &.service {
      background: #ddf3fe; /* Old browsers */
      background: -moz-linear-gradient(top,  #ddf3fe 0%, #d0fbf5 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top,  #ddf3fe 0%,#d0fbf5 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom,  #ddf3fe 0%,#d0fbf5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ddf3fe', endColorstr='#d0fbf5',GradientType=0 ); /* IE6-9 */
      padding: 0 45px 70px;
      .service__cont {
        &__block {
          max-width: 965px;
          margin: 0 auto;
          display: flex;
          &.web {
            .summary {
              background: url(../img/bg_serviceSum_web_pc.jpg) no-repeat center center;
              background-size: cover;
            }
          }
          &.seo {
            .summary {
              background: url(../img/bg_serviceSum_seo_pc.jpg) no-repeat center center;
              background-size: cover;
            }
          }
          &.ad {
            .summary {
              background: url(../img/bg_serviceSum_ad_pc.jpg) no-repeat center center;
              background-size: cover;
            }
          }
          .summary {
            color: #fff;
            width: 70%;
            padding: 5% 3% 3%;
            &__ttl {
              font-size: 2.2rem;
              font-weight: 900;
            }
            &__text {
              font-size: 1.6rem;
              line-height: 1.875;
              margin-top: 22px;
            }
          }
          .serviceList {
            width: 30%;
            min-width: 370px;
            &__item {
              font-size: 2rem;
              font-weight: 900;
              color: #fff;
              width: 100%;
              padding: 34px 0 34px 85px;
              position: relative;
              background: $color-green; /* Old browsers */
              background: -moz-linear-gradient(left,  $color-green 0%, #0070ba 100%); /* FF3.6-15 */
              background: -webkit-linear-gradient(left,  $color-green 0%,#0070ba 100%); /* Chrome10-25,Safari5.1-6 */
              background: linear-gradient(to right,  $color-green 0%,#0070ba 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
              filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-green', endColorstr='#0070ba',GradientType=1 ); /* IE6-9 */
              a {
                font-size: 2rem;
                font-weight: 900;
                color: #fff;
                position: relative;
                z-index: 1;
                @include mq-desktop {
                  &:hover {
                    color: $color-theme;
                  }
                }
              }
              .icon {
                width: 34px;
                position: absolute;
                top: 50%;
                left: -50px;
                transform: translateY(-50%);
              }
              &:nth-child(2) {
                &::before {
                  content: "";
                  width: 100%;
                  height: 100%;
                  display: block;
                  position: absolute;
                  top: 0;
                  left: 0;
                  background: rgba(255,255,255,.1);
                }
              }
              &:nth-child(3) {
                &::before {
                  content: "";
                  width: 100%;
                  height: 100%;
                  display: block;
                  position: absolute;
                  top: 0;
                  left: 0;
                  background: rgba(255,255,255,.2);
                }
              }
            }
          }
          & + .service__cont__block {
            margin-top: 20px;
          }
        }
      }
    }
    &.works {
      background: #3489b0;
      padding: 0 30px 80px;
      .works__cont {
        max-width: 996px;
        margin: 0 auto;
        .worksList {
          display: flex;
          justify-content: space-between;
          &__item {
            width: calc((100% - 50px) / 3);
            .botItem {
              padding: 25px 15px;
              background: #fff;
              .name {
                font-size: 2rem;
                font-weight: 900;
                color: #267ea7;
                &::after {
                  content: "＞";
                  display: inline-block;
                  margin-left: 18px;
                }
              }
              .text {
                font-size: 1.4rem;
                line-height: 1.85714;
                margin-top: 15px;
              }
            }
          }
          &.slick-initialized {
            .worksList__item {
              width: auto;
              width: initial;
              margin: 0 12px;
            }
          }
        }
      }
    }
    &.column {
      .articleListWrap {
        max-width: $base-width;
        margin: 0 auto;
        padding: 0 30px;
      }
    }
    &.outline {
      .outline__cont {
        .gmapWrap {
          &__inner {
            position: relative;
            padding-bottom: 37%;
            padding-top: 30px;
            height: 0;
            overflow: hidden;
          }
          iframe,object,embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
        .accessInfo {
          table {
            border: none;
            margin: 0;
            th,td {
              border: none;
            }
            th {
              background: #fff;
              box-sizing: content-box;
              padding-left: 135px;
            }
            td {
              padding-right: 135px;
            }
            tr {
              &:nth-child(2n) {
                th,td {
                  background: #f8f9fa!important;
                }
              }
            }
          }
        }
      }
    }
    &.contact {
      padding: 40px 1% 100px;
      .contact__cont {
        max-width: 558px;
        margin: 0 auto;
        .contactForm {
          table {
            width: 100%;
            border: none;
            margin: 0;
          }
          th {
            border: none;
            background: none;
            vertical-align: top;
            position: relative;
            &::before {
              content: "";
              width: 2px;
              height: calc(100% - 30px);
              background: $color-theme;
              display: block;
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
            }
            .required {
              font-size: 1.2rem;
              font-weight: normal;
              line-height: 1;
              display: block;
              margin-top: 10px;
              color: #fff;
              background: $color-orange;
              padding: 5px 0;
              width: 4em;
              text-align: center;
            }
          }
          td {
            border: none;
            input,textarea {
              background: #f9f9f9;
              border: 2px solid #dcdcdc;
              border-radius: 3px;
              width: 100%;
              padding: 13px 14px;
            }
            select {
              background: #f9f9f9;
              border: 2px solid #dcdcdc;
              border-radius: 3px;
              padding: 13px 14px;
            }
            input[type="radio"],input[type="checkbox"] {
              margin-right: 5px;
              width: auto;
              width: initial;
              padding: 0;
            }
            textarea {
              min-height : 114px;
            }
            label + label {
              margin-left: 10px;
            }
            .error {
              font-size: 1.4rem;
              font-weight: 700;
              color: $color-form-red;
              margin: 10px 0 0;
              display: block;
            }
            .note {
              font-size: 1.3rem;
              line-height: 1.5;
              display: block;
              margin: 10px 0 0;
              &.red {
                color: $color-form-red;
              }
              & + .note {
                margin: 5px 0 0;
              }
            }
          }
          .confirmingTxt {
            font-size: 1.4rem;
            text-align: center;
            margin: 37px 0;
            input {
              -webkit-appearance: checkbox;
            }
            a {
              text-decoration: underline;
              @include mq-desktop {
                &:hover {
                  text-decoration: none;
                }
              }
            }
          }
        }
        .completedForm {
          .completedMessage {
            font-size: 1.8rem;
            font-weight: 900;
            line-height: 1;
            display: inline-block;
            margin: 24px 0 0;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            &::after {
              content: "";
              width: 100%;
              height: 3px;
              background: $color-theme;
              display: inline-block;
            }
          }
          p {
            text-align: center;
            margin: 40px 0 0;
          }
        }
      }
    }
  }
}

/* =================================
  下層共通
================================= */
.pagenation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 53px auto;
  .pager {
    background: $color-green; /* Old browsers */
    background: -moz-linear-gradient(left,  $color-green 0%, #0070ba 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  $color-green 0%,#0070ba 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  $color-green 0%,#0070ba 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-green', endColorstr='#0070ba',GradientType=1 ); /* IE6-9 */
    li {
      display: inline-block;
      a {
        font-size: 1.6rem;
        font-weight: 900;
        color: #fff;
        width: 35px;
        line-height: 35px;
        text-align: center;
        display: block;
        @include mq-desktop {
          &:hover {
            background: $color-theme;
          }
        }
      }
      span {
        font-size: 1.6rem;
        font-weight: 900;
        color: #fff;
        width: 35px;
        line-height: 35px;
        text-align: center;
        display: block;
      }
      & + li {
        position: relative;
        &::before {
          content: "";
          width: 2px;
          height: 100%;
          background: #fff;
          display: block;
          position: absolute;
          top: 0;
          left: -2px;
        }
      }
    }
  }
}
.under {
  .articleListWrap {
    max-width: $base-width;
    margin: 0 auto;
    padding: 35px 30px 40px;
  }
  .articleTtlWrap {
    background: url(../img/ttl_bg_articleList.jpg) no-repeat center center;
    background-size: cover;
    text-align: center;
    padding: 95px 1% 118px;
    .pageTtl {
      @extend %font-round;
      font-size: 5rem;
      font-weight: 900;
      color: #fff;
      &::before {
        @include icon-base(33px,38px);
        background: url(../img/icon/icon_column_white.png) no-repeat center center;
        background-size: contain;
        margin: -10px 13px 0 0;
      }
    }
    .summary {
      font-size: 1.7rem;
      font-weight: 900;
      color: #fff;
      margin: 15px 0 0;
    }
  }
  .category {
    padding: 68px 30px 30px;
    background: #ddf3fe; /* Old browsers */
    background: -moz-linear-gradient(top,  #ddf3fe 0%, #d0fbf5 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  #ddf3fe 0%,#d0fbf5 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  #ddf3fe 0%,#d0fbf5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ddf3fe', endColorstr='#d0fbf5',GradientType=0 ); /* IE6-9 */
    .ttl--category {
      text-align: center;
      margin: 0 0 60px;
      &__ttl {
        @extend %font-round;
        font-size: 3.2rem;
        font-weight: 900;
        &::before {
          @include icon-base(21px,30px);
          background: url(../img/icon/icon_service_black.png) no-repeat center center;
          background-size: contain;
          margin: 0 16px 0 0;
        }
      }
      &__text {
        font-size: 1.7rem;
        font-weight: 900;
        margin: 20px 0 0;
      }
    }
    .categoryList {
      max-width: $base-width;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      background: $color-green; /* Old browsers */
      background: -moz-linear-gradient(left,  $color-green 0%, #0070ba 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left,  $color-green 0%,#0070ba 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right,  $color-green 0%,#0070ba 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-green', endColorstr='#0070ba',GradientType=1 ); /* IE6-9 */
      &__item {
        width: calc(100% / 3);
        position: relative;
        a {
          font-size: 1.8rem;
          font-weight: 900;
          color: #fff;
          display: block;
          padding: 27px 5px 27px 66px;
          position: relative;
          z-index: 1;
        }
        .icon {
          width: 34px;
          position: absolute;
          top: 50%;
          left: 20px;
          transform: translateY(-50%);
        }
        &::after {
          content: "";
          width: 100%;
          height: 100%;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          background: #fff;
          opacity: 0;
          @include animation-base;
        }
        @include mq-desktop {
          &:hover {
            &::after {
              opacity: .2;
            }
          }
        }
        &:nth-child(n + 4) {
          border-top: 1px solid #fff;
        }
        &:not(:nth-child(3n + 1)) {
          border-left: 1px solid #fff;
        }
      }
    }
  }
}

/* =================================
  article list
================================= */
.articleList {
  &__item {
    display: flex;
    align-items: flex-start;
    background: #fff;
    box-shadow: 0px 0px 65px 0px rgba(1, 51, 74, 0.1);
    .topItem {
      width: 467px;
      padding: 0 0 50px;
      position: relative;
      .thumb {
        width: 100%;
        height: auto;
      }
      .day {
        width: 90px;
        height: 90px;
        background: rgba(0, 104, 153, .9);
        border-radius: 50px;
        text-align: center;
        position: absolute;
        left: 20px;
        bottom: 5px;
        z-index: 6;
        .num {
          @extend %font-round;
          font-size: 2rem;
          line-height: 1.3;
          color: #fff;
          display: block;
          width: 100%;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    .botItem {
      width: calc(100% - 267px);
      padding: 30px;
      .ttl {
        font-size: 2.2rem;
        font-weight: 900;
        line-height: 1.27272;
        color: $color-theme;
      }
      .text {
        font-size: 1.4rem;
        line-height: 1.71428;
        margin: 25px 0 0;
      }
      .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin: 35px 0 0;
        .tag {
          display: flex;
          flex-wrap: wrap;
          &__item {
            margin-right: 14px;
            & > * {
              font-size: 1.4rem;
              color: $color-theme;
              &::before {
                content: "#";
                display: inline-block;
              }
            }
          }
        }
        .view {
          font-size: 1.8rem;
          font-weight: 900;
          color: #e4488d;
          &::before {
            @include icon-base(19px,19px);
            background: url(../img/icon/icon_favo.png) no-repeat center center;
            background-size: contain;
            margin: -3px 5px 0 0;
          }
        }
      }
    }
    & + .articleList__item {
      margin: 30px 0 0;
    }
  }
}
/* =================================
  articleDetail
================================= */
.articleDetail {
  .breadcrumb {
    width: 100%;
    padding: 35px 38px 35px;
    background: url(../img/articleMv_deco.png) no-repeat bottom center;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    ul {
      display: flex;
      align-items: center;
      li {
        color: #fff;
        a {
          color: #fff;
        }
      }
    }
  }
}

/* =================================
  singlePage
================================= */
.singlePage {
  .tocBox {
    margin: 40px 0 50px -45px;
    width: calc(100% + 90px);
    &__ttl {
      @extend %font-round;
      font-size: 3.2rem;
      font-weight: 900;
      color: $color-theme;
      text-align: center;
      &::before {
        @include icon-base(28px,18px);
        background: url(../img/icon/icon_index.png) no-repeat center center;
        background-size: contain;
        margin: 0 14px 4px 0;
      }
    }
    .tocList {
      counter-reset: tocNum;
      margin: 30px auto 0;
      &__item {
        a {
          font-size: 1.6rem;
          font-weight: 900;
          display: block;
          background: #f8f9fa;
          padding: 18px 82px;
          position: relative;
          &::before {
            counter-increment:tocNum;
            content: "0"counter(tocNum);
            position: absolute;
            left: 35px;
          }
          &::after {
            content: "＞";
            font-size: 1.6rem;
            color: $color-green;
            position: absolute;
            top: 50%;
            right: 50px;
            transform: translateY(-50%) rotate(90deg);
          }
          @include mq-desktop {
            &:hover {
              text-decoration: underline;
              &::before {
                text-decoration: none!important;
              }
              &::after {
                text-decoration: none!important;
              }
            }
          }
        }
        &:nth-child(2n) {
          a {
            background: #fff;
          }
        }
      }
    }
  }
  .articleMv {
    width: 100%;
    position: relative;
    .img {
      width: 100%;
      height: auto;
    }
  }
  .data {
    margin: -59px 0 0;
    padding: 0 40px;
    display: flex;
    align-items: flex-end;
    .date {
      width: 117px;
      height: 117px;
      text-align: center;
      background: rgba(0, 104, 153, 0.9);
      border-radius: 50%;
      position: relative;
      .num {
        font-size: 2.6rem;
        line-height: 1.3;
        color: #fff;
        width: 100%;
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .info {
      margin-left: 15px;
      width: calc(100% - 117px);
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .tag {
        display: flex;
        &__item {
          margin-right: 15px;
          & > * {
            font-size: 1.6rem;
            color: $color-theme;
            &::before {
              content: "#";
            }
          }
        }
      }
      .social {
        display: flex;
        &__item {
          .icon {
            width: 23px;
            margin: 0 7px 3px 0;
          }
          .count {
            font-size: 2rem;
            font-weight: 900;
          }
          &.fb {
            color: #4576e3;
          }
          &.tw {
            color: #55b9f3;
          }
          &.view {
            color: #e4488d;
          }
          & + .social__item {
            margin-left: 30px;
          }
        }
      }
    }
  }
  &__in {
    max-width: $base-width;
    margin: 0 auto;
    background: #fff;
    padding: 30px 45px 80px;
    & > {
      .caption {
        font-size: 1.6rem;
        margin: 20px 0 0 -15px;
        width: calc(100% + 30px);
      }
      h1 {
        font-size: 3.2rem;
        color: $color-theme;
        line-height: 1.5;
        margin: 20px 0 20px -15px;
        width: calc(100% + 30px);
      }
      h2 {
        font-size: 2.8rem;
        line-height: 1.5;
        color: #fff;
        background: #3489b0;
        padding: 25px 28px;
        margin: 50px 0 40px;
      }
      h3 {
        font-size: 2.4rem;
        line-height: 1.25;
        border-left: 7px solid #267ea7;
        padding: 2px 20px 4px;
        margin: 40px 0 30px;
        position: relative;
        &::after {
          content: "";
          width: 100%;
          height: 1px;
          background: #d0d0d0;
          display: block;
          position: absolute;
          left: -7px;
          bottom: -7px;
        }
      }
      h4 {
        font-size: 2rem;
        color: $color-theme;
        line-height: 1.25;
        padding: 13px 17px;
        margin: 30px 0 24px;
        background: #e3f4fb;
      }
      h5 {
        font-size: 1.6rem;
        line-height: 1.5;
        color: $color-theme;
        margin: 30px 0 24px;
      }
      p {
        line-height: 1.75;
        & + p {
          margin-top: 35px;
        }
      }
      img {
        display: block;
        margin: 0 auto 40px;
      }
      figure {
        text-align: center;
        margin: 0 auto 40px;
        figcaption {
          font-size: 1.4rem;
          color: #181818;
          margin: 30px 0 0;
        }
      }
      table {
        margin: 40px 0 30px;
      }
      ul:not([class]) {
        padding: 25px 30px;
        margin: 25px auto;
        background: #e9f6fb;
        li {
          font-size: 1.6rem;
          font-weight: 900;
          line-height: 1.75;
          color: $color-theme;
          padding-left: 20px;
          position: relative;
          &::before {
            content: "・";
            color: $color-theme;
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
          }
          &+ li {
            margin: 15px 0 0;
          }
        }
      }
      ol:not([class]) {
        counter-reset: counter;
        padding: 25px 30px;
        margin: 25px auto;
        background: #f8f9fa;
        li {
          font-size: 1.6rem;
          font-weight: 900;
          line-height: 1.75;
          padding-left: 30px;
          position: relative;
          &::before {
            counter-increment: counter;
            content: "0"counter(counter)".";
            font-size: 1.6rem;
            font-weight: 900;
            line-height: 1;
            display: inline-block;
            position: absolute;
            left: 0;
            top: 5px;
          }
          &+ li {
            margin: 15px 0 0;
          }
        }
      }
      blockquote {
        position: relative;
        padding: 5px 12px 5px 56px;
        margin: 20px 0;
        box-sizing: border-box;
        font-style: italic;
        color: #464646;
        background: #f8fafb;
        &::before {
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 10px;
          width: 40px;
          height: 40px;
          -ms-transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          vertical-align: middle;
          text-align: center;
          content: "“";
          color: #fff;
          font-size: 5rem;
          line-height: 1.2;
          text-indent: -0.3em;
          background: $color-theme;
          border-radius: 50%;
        }
        p {
          position: relative;
          padding: 0;
          margin: 10px 0;
          z-index: 3;
          line-height: 1.7;
        }
        cite {
          display: block;
          text-align: right;
          line-height: 1;
          color: #888888;
          font-size: 0.9em;
          margin: 10px 0 0;
        }
      }
    }
  }
  .pushBox {
    background: #fff;
    border: 6px solid #ddf3fe;
    padding: 30px;
    margin: 50px auto 80px;
    position: relative;
    .label {
      font-size: 1.2rem;
      font-weight: 900;
      color: #fff;
      background: #267ea7;
      padding: 6px 13px;
      margin: 0 0 13px;
      display: inline-block;
      border-radius: 3px;
    }
    &__cont {
      display: flex;
      align-items: flex-start;
      flex-direction: row-reverse;
      .topItem {
        width: 328px;
        margin-left: 30px;
        .thumb {
          width: 100%;
          height: auto;
        }
      }
      .botItem {
        width: calc(100% - 358px);
        .ttl {
          font-size: 2rem;
          font-weight: 900;
          color: #267ea7;
        }
        .text {
          font-size: 1.4rem;
          line-height: 1.85714;
          margin: 17px 0 0;
        }
      }
    }
  }
  .col2Box {
    display: flex;
    align-items: flex-start;
    box-shadow: 0px 0px 65px 0px rgba(1, 51, 74, 0.1);
    background: #fff;
    margin: 40px auto 50px;
    .topItem {
      width: 50%;
    }
    .botItem {
      width: 50%;
      font-size: 1.6rem;
      line-height: 2.25;
      padding: 34px;
    }
  }
}
.relatedBox {
  max-width: $base-width;
  margin: 0 auto;
  padding: 80px 30px;
  .ttl--related {
    text-align: center;
    margin: 0 0 65px;
    &__ttl {
      @extend %font-round;
      font-size: 3.2rem;
      font-weight: 900;
      color: $color-theme;
      &::before {
        @include icon-base(26px,30px);
        background: url(../img/icon/icon_column_blue.png) no-repeat center center;
        background-size: contain;
        margin: 0 12px 5px 0;
      }
    }
    &__text {
      font-size: 1.7rem;
      font-weight: 900;
      margin: 20px 0 0;
    }
  }
}
@media screen and (max-width: 1354px) {
  .topPage {
    .mv {
      &__inner {
        text-align: center;
        .telWrap {
          text-align: center;
          display: inline-block;
          padding: 13px 35px 13px 43px;
          .numWrap {
            display: block;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1184px) {
  /* =================================
    toppage
  ================================= */
  .topPage {
    .topSec {
      &.service {
        padding: 0 5% 42px;
        .service__cont {
          &__block {
            max-width: auto;
            max-width: initial;
            display: block;
            &.web {
              .summary {
                background: url(../img/bg_serviceSum_web_sp.jpg) no-repeat top center;
                background-size: cover;
              }
            }
            &.seo {
              .summary {
                background: url(../img/bg_serviceSum_seo_sp.jpg) no-repeat top center;
                background-size: cover;
              }
            }
            &.ad {
              .summary {
                background: url(../img/bg_serviceSum_ad_sp.jpg) no-repeat top center;
                background-size: cover;
              }
            }
            .summary {
              width: 100%;
              padding: 50px 5% 20px;
              &__ttl {
                font-size: 1.9rem;
              }
              &__text {
                font-size: 1.2rem;
                line-height: 1.66666;
                margin-top: 15px;
              }
            }
            .serviceList {
              width: 100%;
              min-width: auto;
              min-width: initial;
              &__item {
                font-size: 1.7rem;
                width: 100%;
                padding: 20px 0 20px 55px;
                a {
                  font-size: 1.7rem;
                }
                .icon {
                  width: 24px;
                  left: -40px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: $display-width-s) {
  /* =================================
    cv banner
  ================================= */
  .cvItem--01 {
    padding: 40px 5% 30px;
    &.orange {
      background: url(../img/bg_cvItem01.jpg) no-repeat center center;
      background-size: cover;
      .telWrap {
        .num {
          &::before {
            width: 29px;
            height: 29px;
            margin: 3px 6px 0 0;
          }
        }
      }
      .mailWrap {
        a {
          &::before {
            width: 28px;
            height: 21px;
            margin-right: 6px;
          }
        }
      }
    }
    &__inner {
      max-width: auto;
      max-width: initial;
      .cvTtl {
        font-size: 2.5rem;
      }
      .text {
        font-size: 1.2rem;
        line-height: 1.58333;
        margin: 15px 0 0;
      }
      .telWrap {
        padding: 15px 15px 15px 15px;
        .num {
          font-size: 2.9rem;
        }
        .receptionTime {
          font-size: 1.3rem;
        }
      }
      .mailWrap {
        a {
          font-size: 2rem;
          padding: 20px 5%;
        }
      }
    }
  }
  .cvItem--02 {
    padding: 40px 5% 30px;
    &__inner {
      .cvTtl {
        font-size: 2.5rem;
      }
      .text {
        font-size: 1.2rem;
        line-height: 1.58333;
        margin: 15px 0 0;
      }
      .mailWrap {
        max-width: auto;
        max-width: initial;
        a {
          font-size: 2rem;
          padding: 25px 5%;
          &::before {
            width: 28px;
            height: 21px;
            margin-right: 6px;
          }
        }
      }
    }
  }
  /* =================================
    toppage
  ================================= */
  .topPage {
    //トップページ共通
    .ttl--topMain {
      padding: 35px 0 25px;
      &__ttl {
        font-size: 2.5rem;
        &.web {
          &::before {
            width: 25px;
            height: 26px;
            margin: 0 7px 3px 0;
          }
        }
        &.media {
          &::before {
            width: 23px;
            height: 17px;
            margin: 0 8px 0 0;
          }
        }
        &.info {
          &::before {
            width: 18px;
            height: 26px;
            margin: 0 10px 0 0;
          }
        }
        &.service {
          &::before {
            width: 16px;
            height: 28px;
            margin: 0 5px 0 0;
          }
        }
        &.works {
          &::before {
            width: 24px;
            height: 17px;
            margin: 0 5px 0 0;
          }
        }
        &.column {
          &::before {
            width: 18px;
            height: 21px;
            margin: 0 5px 0 0;
          }
        }
        &.outline {
          &::before {
            width: 17px;
            height: 18px;
            margin: 0 5px 0 0;
          }
        }
        &.contact {
          &::before {
            width: 26px;
            height: 16px;
            margin: 0 5px 0 0;
          }
        }
      }
      &__text {
        font-size: 1.3rem;
        margin-top: 10px;
      }
    }
    .mv {
      margin-top: 50px;
      padding: 63px 5% 57px;
      &__inner {
        max-width: auto;
        max-width: initial;
        .text--catch {
          &__main {
            margin-top: 20px;
          }
          &__sub {
            position: static;
          }
        }
        .telWrap {
          padding: 12px 10px;
          margin: 20px 0 0;
          display: block;
          text-align: center;
          position: relative;
          .text {
            font-size: 1.3rem;
            &::before {
              content: none;
            }
          }
          .numWrap {
            display: block;
            .num {
              font-size: 3rem;
              background: -webkit-linear-gradient(0deg, $color-green, $color-theme);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              margin: 5px 0 0;
              display: block;
              position: relative;
              &::before {
                content: "";
                width: 23px;
                height: 23px;
                display: inline-block;
                background: url(../img/icon/icon_tel_grad.png) no-repeat center center;
                background-size: contain;
                margin-right: 10px;
              }
            }
            .receptionTime {
              font-size: 1.1rem;
            }
          }
        }
      }
    }
    .topSec {
      &.webConsul {
        .webConsul__cont {
          background: url(../img/bg_webConsul_sp.jpg) no-repeat top center;
          background-size: cover;
          min-height: auto;
          min-height: initial;
          padding: 0 10% 0;
          .textWrap {
            width: 100%;
            margin: 0 auto;
            padding: 40px 6% 45px;
            top: 70px;
            box-shadow: 0px 0px 65px 0px rgba(1, 44, 64, 0.3);
            &::after {
              width: calc(100% - 60px);
              top: 50px;
              left: 30px;
            }
            &__ttl {
              font-size: 1.9rem;
              margin: 0 0 20px;
              padding: 0 15px 0 0;
            }
            &__text {
              font-size: 1.2rem;
              line-height: 1.75;
            }
          }
        }
      }
      &.media {
        background: url(../img/bg_media_sp.jpg) repeat-y center top;
        background-size: cover;
        padding: 70px 5% 28px;
        .media__cont {
          .mediaList {
            &__item {
              padding: 0;
              .topItem {
                width: 100%;
                margin: 0 0 18px;
                position: static;
                img {
                  margin: auto;
                }
              }
              .botItem {
                max-width: auto;
                max-width: initial;
                .name {
                  font-size: 2rem;
                  text-align: center;
                }
                .text {
                  font-size: 1.3rem;
                  line-height: 1.46153;
                  margin: 14px 0 0;
                }
                .btn {
                  margin: 20px auto 0;
                  display: block;
                  text-align: center;
                }
              }
            }
            &.slick-initialized {
              .mediaList__item {
                padding-top: 70px;
              }
            }
          }
        }
      }
      &.info {
        .infoList {
          max-width: auto;
          max-width: initial;
          padding: 0 5% 28px;
          &__item {
            padding: 10px 40px 10px 13px;
            display: block;
            &::after {
              right: 13px;
            }
            .head {
              margin: 0 0 10px;
              .day {
                font-size: 1.2rem;
                margin-right: 10px;
              }
              .tag {
                font-size: 1.1rem;
                padding: 5px 10px;
              }
            }
          }
        }
      }
      &.works {
        padding: 0 0 35px;
        .works__cont {
          max-width: auto;
          max-width: initial;
          .worksList {
            display: block;
            &__item {
              width: 90%;
              margin: 0 auto;
              background: #fff;
              .topItem {
                .thumb {
                  width: 100%;
                }
              }
              .botItem {
                padding: 25px 20px;
                .name {
                  font-size: 1.8rem;
                  padding-right: 18px;
                  position: relative;
                  &::after {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                  }
                }
                .text {
                  font-size: 1.3rem;
                }
              }
              & + .worksList__item {
                margin-top: 20px;
              }
            }
            &.slick-initialized {
              .worksList__item {
                width: 100%;
                width: auto;
                width: initial;
                margin: 70px 11px 0;
              }
              .slick-prev {
                top: 34%;
                left: 13%;
              }
              .slick-next {
                top: 34%;
                right: 13%;
              }
            }
          }
        }
      }
      &.column {
        background: url(../img/bg.jpg) repeat center top;
        background-size: contain;
        .articleListWrap {
          padding: 0 5% 32px;
        }
      }
      &.outline {
        .outline__cont {
          .accessInfo {
            table {
              display: table;
              white-space: initial;
              th {
                padding-left: 5%;
                width: 7em;
              }
              td {
                padding-right: 5%;
              }
            }
          }
        }
      }
      &.contact {
        padding: 20px 5% 40px;
        .contact__cont {
          max-width: auto;
          max-width: initial;
          .contactForm {
            table {
              white-space: initial;
            }
            tr {
              display: block;
            }
            th {
              display: block;
              width: 100%;
              padding: 5px 10px;
              &::before {
                height: 100%;
              }
              .required {
                display: inline-block;
                margin: 0 0 0 10px;
                color: #fff;
                background: $color-orange;
                padding: 5px 0;
                width: 4em;
                text-align: center;
              }
            }
            td {
              font-size: 1.6rem;
              padding: 10px 0 20px;
              width: 100%;
              display: block;
              input,textarea {
                padding: 12px 12px;
              }
              select {
                padding: 12px 12px;
              }
              .error {
                font-size: 1.2rem;
                margin: 5px 0 0;
              }
              .note {
                font-size: 1.2rem;
                line-height: 1.25;
                margin: 5px 0 0;
              }
            }
            .confirmingTxt {
              font-size: 1.2rem;
              margin: 20px 0;
            }
          }
          .completedForm {
            .completedMessage {
              font-size: 1.6rem;
              margin: 15px 0 0;
            }
            p {
              font-size: 1.4rem;
              margin: 20px 0 0;
            }
          }
        }
      }
    }
  }
  /* =================================
    下層共通
  ================================= */
  .pagenation {
    margin: 40px auto;
    .pager {
      li {
        a {
          font-size: 1.4rem;
        }
        span {
          font-size: 1.4rem;
        }
      }
    }
  }
  .under {
    .articleListWrap {
      padding: 30px 5% 20px;
    }
    .articleTtlWrap {
      padding: 45px 5% 50px;
      .pageTtl {
        font-size: 2.8rem;
        &::before {
          width: 24px;
          height: 29px;
          margin: -10px 6px 0 0;
        }
      }
      .summary {
        font-size: 1.5rem;
        margin: 12px 0 0;
      }
    }
    .category {
      padding: 45px 5% 30px;
      .ttl--category {
        margin: 0 0 25px;
        &__ttl {
          font-size: 2.5rem;
          &::before {
            width: 16px;
            height: 28px;
            margin: 0 5px 0 0;
          }
        }
        &__text {
          font-size: 1.3rem;
          margin: 10px 0 0;
        }
      }
      .categoryList {
        &__item {
          width: 100%;
          a {
            font-size: 1.2rem;
            padding: 15px 5% 15px 13%;
          }
          .icon {
            width: 16px;
            left: 5%;
          }
          &:nth-child(n + 2) {
            border-top: 1px solid #fff;
            // border-top: none;
          }
          &:not(:nth-child(3n + 1)) {
            border-left: none;
          }
        }
      }
    }
  }
  /* =================================
    article list
  ================================= */
  .articleList {
    &__item {
      display: block;
      .topItem {
        width: 100%;
        padding: 0;
        .day {
          width: 72px;
          height: 72px;
          left: 5%;
          bottom: -19px;
          .num {
            font-size: 1.6rem;
            line-height: 1.28125;
          }
        }
      }
      .botItem {
        width: 100%;
        padding: 27px 5% 25px;
        .ttl {
          font-size: 1.7rem;
        }
        .text {
          font-size: 1.2rem;
          line-height: 1.41666;
          margin: 15px 0 0;
        }
        .info {
          margin: 22px 0 0;
          .tag {
            &__item {
              margin-right: 10px;
              & > * {
                font-size: 1.2rem;
              }
            }
          }
          .view {
            font-size: 1.5rem;
            &::before {
              width: 15px;
              height: 16px;
            }
          }
        }
      }
      & + .articleList__item {
        margin: 17px 0 0;
      }
    }
  }
  /* =================================
    articleDetail
  ================================= */
  .articleDetail {
    .breadcrumb {
      padding: 20px 5% 20px;
    }
  }
  /* =================================
    singlePage
  ================================= */
  .singlePage {
    .tocBox {
      margin: 35px 0 45px -5%;
      width: calc(100% + 10%);
      &__ttl {
        font-size: 2.5rem;
        &::before {
          width: 24px;
          height: 14px;
          margin: 0 7px 4px 0;
        }
      }
      .tocList {
        margin: 25px auto 0;
        &__item {
          a {
            font-size: 1.5rem;
            padding: 12px 10% 12px 13%;
            &::before {
              left: 5%;
            }
            &::after {
              right: 3%;
            }
          }
        }
      }
    }
    .data {
      margin: -63px 0 0;
      padding: 0 5%;
      display: block;
      .date {
        width: 86px;
        height: 86px;
        .num {
          font-size: 2rem;
        }
      }
      .info {
        margin: 10px 0 0;
        width: 100%;
        display: block;
        .tag {
          &__item {
            margin-right: 11px;
            & > * {
              font-size: 1.4rem;
            }
          }
        }
        .social {
          margin: 10px 0 0;
          &__item {
            .icon {
              width: 18px;
              margin: 0 5px 5px 0;
            }
            .count {
              font-size: 1.5rem;
            }
            & + .social__item {
              margin-left: 15px;
            }
          }
        }
      }
    }
    &__in {
      padding: 20px 5% 40px;
      & > {
        .caption {
          font-size: 1.5rem;
          margin: 10px 0 0 -2%;
          width: calc(100% + 4%);
        }
        h1 {
          font-size: 2.5rem;
          margin: 15px 0 20px -2%;
          width: calc(100% + 4%);
        }
        h2 {
          font-size: 2.2rem;
          padding: 15px 5%;
          margin: 40px 0 30px;
        }
        h3 {
          font-size: 2rem;
          border-left: 5px solid #267ea7;
          padding: 2px 4% 4px;
          margin: 35px 0 25px;
          &::after {
            left: -5px;
            bottom: -5px;
          }
        }
        h4 {
          font-size: 1.8rem;
          padding: 10px 4%;
          margin: 30px 0 20px;
        }
        h5 {
          font-size: 1.5rem;
          margin: 30px 0 20px;
        }
        p {
          font-size: 1.5rem;
          & + p {
            margin-top: 25px;
          }
        }
        img {
          display: block;
          margin: 0 auto 30px;
        }
        figure {
          margin: 0 auto 30px;
          figcaption {
            font-size: 1.2rem;
            margin: 15px 0 0;
          }
        }
        table {
          margin: 35px 0 25px;
        }
        ul:not([class]) {
          padding: 15px 5%;
          margin: 25px auto;
          li {
            font-size: 1.5rem;
            &+ li {
              margin: 10px 0 0;
            }
          }
        }
        ol:not([class]) {
          padding: 15px 5%;
          margin: 25px auto;
          background: #f8f9fa;
          li {
            font-size: 1.5rem;
            &::before {
              font-size: 1.5rem;
            }
            &+ li {
              margin: 10px 0 0;
            }
          }
        }
        blockquote {
          padding: 5px 12px 5px 68px;
          &::before {
            left: 5%;
            width: 35px;
            height: 35px;
            font-size: 3.8rem;
            line-height: 1.3;
          }
          p {
            font-size: 1.4rem;
            line-height: 1.65;
          }
        }
      }
    }
    .pushBox {
      border: 4px solid #ddf3fe;
      padding: 30px 5%;
      margin: 30px auto 40px;
      font-size: 1.5rem;
      .label {
        font-size: 1.2rem;
        padding: 6px 10px;
      }
      &__cont {
        display: block;
        .topItem {
          width: 100%;
          margin: 0 0 18px;
        }
        .botItem {
          width: 100%;
          .ttl {
            font-size: 1.5rem;
          }
          .text {
            margin: 10px 0 0;
          }
        }
      }
    }
    .col2Box {
      display: block;
      margin: 25px auto 40px;
      .topItem {
        width: 100%;
      }
      .botItem {
        width: 100%;
        font-size: 1.5rem;
        padding: 5%;
      }
    }
  }
  .relatedBox {
    padding: 40px 5%;
    .ttl--related {
      text-align: center;
      margin: 0 0 35px;
      &__ttl {
        font-size: 2.5rem;
        &::before {
          width: 18px;
          height: 21px;
          margin: 0 5px px 0;
        }
      }
      &__text {
        font-size: 1.3rem;
        margin: 10px 0 0;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .topPage {
    .topSec.media {
      background: url(../img/bg_media_sp.jpg) repeat-y center top;
      background-size: contain;
    }
  }
}