@charset "utf-8";

/*=================================
  footer
=================================*/
.footer {
  padding: 40px 44px;
  .footerCont {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    align-items: center;
    .fNav {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      &__item {
        a {
          font-size: 1.4rem;
          color: #999;
          display: block;
          @include mq-desktop {
            &:hover {
              text-decoration: underline;
            }
          }
        }
        & + .fNav__item {
          margin-left: 20px;
        }
      }
    }
    .copy {
      font-size: 1.4rem;
      line-height: 1;
      color: #999;
      display: block;
    }
  }
}

@media screen and (max-width: 1260px){
  /*=================================
    footer
  =================================*/
  .footer {
    padding: 20px 5%;
    .footerCont {
      .fNav {
        &__item {
          a {
            font-size: 1.2rem;
          }
          & + .fNav__item {
            margin-left: 20px;
          }
        }
      }
      .copy {
        font-size: 1rem;
      }
    }
  }
}