@charset "utf-8";

/* =================================
  header
================================= */
.header {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background: #fff;
  box-shadow: 0px 0px 65px 0px rgba(1, 51, 74, 0.1);
  z-index: 9999;
  &Inner {
    width: $side-width;
    overflow-y: auto;
    padding: 15% 20px 20px;
    max-height: calc(100vh - 155px);
  }
}
.header__top {
  .ttl--site {
    width: 100%;
    a {
      display: block;
      max-width: 162px;
      margin: 0 auto;
      img {
        display: block;
      }
    }
    &__sub {
      font-size: 1.2rem;
      font-weight: bold;
      color: $font-color-light;
      text-align: center;
      display: block;
      margin: 24px 0 0;
    }
  }
}
.header__other {
  .snsList {
    position: fixed;
    top: 55px;
    left: calc(100% - 175px);
    display: flex;
    &__item {
      width: 40px;
      transform: rotateY(0);
      @include animation-base;
      a {
        display: block;
      }
      & + .snsList__item {
        margin-left: 15px;
      }
      @include mq-desktop {
        &:hover {
          transform: rotateY(-360deg);
        }
      }
    }
  }
}
.gNav {
  .gNav__list {
    padding: 15% 0 0;
    &__item {
      width: 100%;
      a {
        font-size: 1.8rem;
        font-weight: bold;
        color: $font-color-base;
        line-height: 1;
        display: block;
        padding: 6% 0 6% 30px;
        position: relative;
        &::before {
          content: "";
          width: 0;
          height: 0;
          display: block;
          position: absolute;
          top: 50%;
          left: -4px;
          border-radius: 0;
          transform: translateY(-50%);
          @include animation-base;
        }
        &::after {
          content: "";
          width: 14px;
          height: 2px;
          background: #c0c0c0;
          display: block;
          position: absolute;
          top: 50%;
          left: 0;
          border-radius: 0;
          transform: translateY(-50%);
          @include animation-base;
        }
        @include mq-desktop {
          &:hover {
            color: $color-theme;
            &::before {
              border-radius: 50%;
              background: #b5e1f6;
              width: 22px;
              height: 22px;
            }
            &::after {
              border-radius: 50%;
              background: #0974a7;
              width: 14px;
              height: 14px;
            }
          }
        }
      }
      & + .gNav__list__item {
        margin-top: 15px;
      }
    }
  }
}
.header--contact {
  padding: 0 20px;
  width: 100%;
  position: absolute;
  bottom: 10px;
  left: 0;
  .telBox {
    position: relative;
    text-align: center;
    .num {
      @extend %font-round;
      font-size: 2.6rem;
      font-weight: 900;
      color: $color-orange;
    }
    .receptionTime {
      font-size: 1.4rem;
      color: $color-orange;
    }
    &::before {
      @include icon-base(28px,28px);
      background: url(../img/icon/icon_tel_orange.png) no-repeat center center;
      background-size: contain;
      animation: vibrate02 2.5s infinite;
    }
    @include mq-desktop {
      &:hover {
        .num {
          color: $color-theme;
        }
        .receptionTime {
          color: $color-theme;
        }
        &::before {
          background: url(../img/icon/icon_tel_blue.png) no-repeat center center;
          background-size: contain;
          animation: vibrate .5s 1;
        }
      }
    }
  }
  .mailBox {
    margin-top: 35px;
    a {
      font-size: 1.7rem;
      color: #fff;
      display: block;
      padding: 15px 0px;
      background: $color-red;
      text-align: center;
      border-radius: 3px;
      max-width: 95%;
      margin: 0 auto;
      &::before {
        @include icon-base(22px,17px);
        background: url(../img/icon/icon_mail_white.png) no-repeat center center;
        background-size: contain;
        margin-right: 10px;
      }
    }
    @include mq-desktop {
      &:hover {
        a {
          background: $color-theme;
        }
      }
    }
  }
}
@media screen and (max-width: 1480px) {
  .header__other {
    .snsList {
      left: 85%;
    }
  }
}
@media screen and (max-width: $display-width-s) {
  /* =================================
    header
  ================================= */
  .header {
    width: 100%;
    height: auto;
    height: initial;
    background: rgba(255,255,255,.8);
    &Inner {
      max-height: 90vh;
      width: 80%;
      padding: 0;
      display: none;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      z-index: 220;
      box-shadow: 0px 0px 65px 0px rgba(1, 44, 64, 0.3);
      .btn--headerClose {
        width: 37px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 230;
      }
    }
    .logo--sub {
      padding: 10px 0 10px 5%;
      img {
        width: 112px;
      }
    }
  }
  .header__top {
    background: url(../img/bg_headerHead.jpg) no-repeat center center;
    background-size: cover;
    padding: 35px 24px 50px;
    .ttl--site {
      text-align: left;
      a {
        max-width: 125px;
        display: inline-block;
        pointer-events: none;
        img {
          display: block;
        }
      }
      &__sub {
        font-size: 1.2rem;
        text-align: left;
        margin: 8px 0 0;
      }
    }
  }
  .header__other {
    background: #fff;
    padding: 33px 0;
    position: relative;
    .snsList {
      position: absolute;
      top: -18px;
      left: 50%;
      transform: translateX(-50%);
      &__item {
        width: 37px;
      }
    }
    .telBox {
      text-align: center;
      .num {
        font-size: 2.6rem;
        font-weight: bold;
        font-family: "Nunito";
        &::before {
          @include icon-base(20px,20px);
          background: url(../img/icon/icon_tel_blue.png) no-repeat center center;
          background-size: contain;
          margin-right: 5px;
        }
      }
      .receptionTime {
        font-size: 1.2rem;
        color: #000;
        margin: 10px 0 0;
      }
    }
  }
  .gNav {
    .gNav__list {
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      background: #fff;
      border-top: 1px solid #c0c0c0;
      &__item {
        width: 50%;
        a {
          font-size: 1.5rem;
          padding: 22px 0 22px 18px;
          background: #fff;
          border-bottom: 1px solid #c0c0c0;
          &::before {
            width: 15px;
            height: 15px;
            left: initial;
            right: 12px;
            border-radius: 50%;
            background: #b5e1f6;
          }
          &::after {
            width: 9px;
            height: 9px;
            background: #0974a7;
            left: initial;
            right: 15px;
            border-radius: 50%;
          }
        }
        & + .gNav__list__item {
          margin-top: 0;
        }
        &:nth-child(2n - 1) {
          a {
            border-right: 1px solid #c0c0c0;
          }
        }
      }
      &::after {
        content: "";
        width: 100%;
        height: 1px;
        background: #fff;
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }
  .headerNav__cover {
    height: 100vh;
    width: 100vw;
    display: none;
    background: rgba(255,255,255,0.7);
    position: absolute;
    top: 0;
    z-index: 210;
  }
  /*バーガーボタン設定*/
  .burger {
    width: 60px;
    height: 50px;
    background: #2590bd;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    z-index: 205;
    span {
      width: 21px;
      height: 2px;
      display: block;
      background: #fff;
      border-radius: 50px;
      position: absolute;
      top: 20px;
      right: 0;
      left: 0;
      margin: 0 auto;
      transition: width 0.2s, right 0.2s, left 0.2s;
      &.burger--top{
        transform: translateY(-5px);
      }
      &.burger--middle{
        transform: translateY(0px);
        position: relative;
        background: none;
        &::before{
          content: "";
          display: block;
          position: absolute;
          width: 21px;
          height: 2px;
          border-radius: 50px;
          background: #fff;
          transition: all 0.2s;
          transform: rotate(0deg);
        }
        &::after{
          content: "";
          display: block;
          position: absolute;
          width: 21px;
          height: 2px;
          border-radius: 50px;
          background: #fff;
          transition: all 0.2s;
          transform: rotate(0deg);
        }
      }
      &.burger--bottom{
        transform: translateY(5px);
      }
    }
    &::after {
      content: "メニュー";
      font-size: 1rem;
      color: #fff;
      text-align: center;
      width: 100%;
      position: absolute;
      bottom: 5px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .header--contact {
    width: auto;
    width: initial;
    margin-top: 0;
    padding: 0;
    position: fixed;
    top: 0;
    bottom: initial;
    left: initial;
    right: 60px;
    display: flex;
    .telBox {
      padding: 8px 0 7px;
      width: 60px;
      height: 50px;
      text-align: center;
      background: $color-orange;
      border-right: 1px solid #fff;
      .num {
        font-size: 1rem;
        color: #fff;
      }
      &::before {
        @include icon-base(18px,18px);
        background: url(../img/icon/icon_tel_white.png) no-repeat center center;
        background-size: contain;
        position: static;
        transform: translate(0,0);
        margin-bottom: 5px;
      }
    }
    .mailBox {
      margin-top: 0;
      width: 60px;
      height: 50px;
      text-align: center;
      border-right: 1px solid #fff;
      a {
        font-size: 1rem;
        padding: 10px 0 5px;
        border-radius: 0;
        max-width: 100%;
        &::before {
          @include icon-base(19px,15px);
          margin: 0 auto 5px;
          display: block;
        }
      }
    }
  }
}