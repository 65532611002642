@charset "UTF-8";


@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700;900&display=swap");
body {
  word-break: break-all;
  font-feature-settings: "palt";
  background: url(../img/bg.jpg) repeat-y top right;
}

/* Noto Sans JP */
/* Nunito */
.header--contact .telBox .num, .cvItem--01__inner .telWrap .num, .topPage .ttl--topMain__ttl, .topPage .mv__inner .telWrap .numWrap .num, .under .articleTtlWrap .pageTtl, .under .category .ttl--category__ttl, .articleList__item .topItem .day .num, .singlePage .tocBox__ttl, .relatedBox .ttl--related__ttl {
  font-family: 'Nunito', sans-serif;
}

@keyframes vibrate {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  75% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes vibrate02 {
  0% {
    transform: rotate(0deg) scale(1.2);
  }
  5% {
    transform: rotate(15deg) scale(1.2);
  }
  10% {
    transform: rotate(-15deg) scale(1.2);
  }
  15% {
    transform: rotate(15deg) scale(1.2);
  }
  20% {
    transform: rotate(0deg) scale(1);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
}

@keyframes scroll {
  0% {
    height: 0;
    top: 0;
    opacity: 0;
  }
  50% {
    height: 140px;
    top: 0;
    opacity: 1;
  }
  100% {
    height: 0;
    top: 140px;
    opacity: 0;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 1;
    display: block;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
    display: block;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

@media screen and (min-width: 769px) {
  /*----------------------------------
    スクロールで画像がふわっと出てくる
    ----------------------------------*/
  .animation--fadeup {
    transition-property: all;
    transition-duration: 0.7s;
    transition-timing-function: ease-out;
    opacity: 0;
    transform: translateY(70px);
  }
  .animation--fadeup.active {
    opacity: 1;
    transform: translateY(0);
  }
  .animation--fadedown {
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: ease-out;
    transition-delay: .2s;
    opacity: 0;
    transform: translateY(-50px);
  }
  .animation--fadedown.active {
    opacity: 1;
    transform: translateY(0);
  }
  /*----------------------------------
    ズームする
    ----------------------------------*/
  .animation--zoom {
    overflow: hidden;
  }
  .animation--zoom img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
  }
  /*----------------------------------
    右に動く矢印
    ----------------------------------*/
  @keyframes slideRight {
    0% {
      right: 10px;
      opacity: 1;
    }
    100% {
      right: 0;
      opacity: 0;
    }
  }
  @keyframes slideRight02 {
    0% {
      right: 18px;
      opacity: 1;
    }
    100% {
      right: 0;
      opacity: 0;
    }
  }
}

a:hover {
  outline: none;
}

a:active {
  outline: none;
}

a:focus {
  outline: none;
}

@media screen and (min-width: 1024px) {
  /* =================================
    hover
  ================================= */
  a {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
  }
  a:hover {
    color: #006899;
  }
  .widelink {
    cursor: pointer;
  }
  .widelink .widelink__cover {
    position: relative;
    overflow: hidden;
  }
  .widelink .widelink__cover::before {
    content: "詳しく見る";
    font-size: 1.4rem;
    color: #fff;
    letter-spacing: .06em;
    line-height: 1;
    text-align: center;
    opacity: 0;
    padding: 10px 0;
    border: 1px solid #b5e1f6;
    background: #006899;
    width: 45%;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 6;
    transition-property: opacity,top;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink .widelink__cover::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0;
    background-color: rgba(31, 31, 31, 0.5);
    color: white;
    transition-property: opacity;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink:hover a {
    text-decoration: none;
    color: #006899;
  }
  .widelink:hover .widelink__cover::before {
    opacity: 1;
    top: 50%;
  }
  .widelink:hover .widelink__cover::after {
    opacity: 1;
  }
  .hoverBg .widelink__cover::before {
    content: none;
  }
  .hoverBg .widelink__cover::after {
    content: none;
  }
  .hoverBg:hover {
    background: #ebfafa;
  }
  /*ボタン*/
  .btnWrap a::after {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
  }
  .btnWrap a:hover {
    color: #fff;
    opacity: .8;
  }
}

/* =================================
  header
================================= */
.header {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background: #fff;
  box-shadow: 0px 0px 65px 0px rgba(1, 51, 74, 0.1);
  z-index: 9999;
}

.headerInner {
  width: 265px;
  overflow-y: auto;
  padding: 15% 20px 20px;
  max-height: calc(100vh - 155px);
}

.header__top .ttl--site {
  width: 100%;
}

.header__top .ttl--site a {
  display: block;
  max-width: 162px;
  margin: 0 auto;
}

.header__top .ttl--site a img {
  display: block;
}

.header__top .ttl--site__sub {
  font-size: 1.2rem;
  font-weight: bold;
  color: #181818;
  text-align: center;
  display: block;
  margin: 24px 0 0;
}

.header__other .snsList {
  position: fixed;
  top: 55px;
  left: calc(100% - 175px);
  display: flex;
}

.header__other .snsList__item {
  width: 40px;
  transform: rotateY(0);
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}

.header__other .snsList__item a {
  display: block;
}

.header__other .snsList__item + .snsList__item {
  margin-left: 15px;
}

@media screen and (min-width: 1024px) {
  .header__other .snsList__item:hover {
    transform: rotateY(-360deg);
  }
}

.gNav .gNav__list {
  padding: 15% 0 0;
}

.gNav .gNav__list__item {
  width: 100%;
}

.gNav .gNav__list__item a {
  font-size: 1.8rem;
  font-weight: bold;
  color: #14191d;
  line-height: 1;
  display: block;
  padding: 6% 0 6% 30px;
  position: relative;
}

.gNav .gNav__list__item a::before {
  content: "";
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  top: 50%;
  left: -4px;
  border-radius: 0;
  transform: translateY(-50%);
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}

.gNav .gNav__list__item a::after {
  content: "";
  width: 14px;
  height: 2px;
  background: #c0c0c0;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  border-radius: 0;
  transform: translateY(-50%);
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}

@media screen and (min-width: 1024px) {
  .gNav .gNav__list__item a:hover {
    color: #006899;
  }
  .gNav .gNav__list__item a:hover::before {
    border-radius: 50%;
    background: #b5e1f6;
    width: 22px;
    height: 22px;
  }
  .gNav .gNav__list__item a:hover::after {
    border-radius: 50%;
    background: #0974a7;
    width: 14px;
    height: 14px;
  }
}

.gNav .gNav__list__item + .gNav__list__item {
  margin-top: 15px;
}

.header--contact {
  padding: 0 20px;
  width: 100%;
  position: absolute;
  bottom: 10px;
  left: 0;
}

.header--contact .telBox {
  position: relative;
  text-align: center;
}

.header--contact .telBox .num {
  font-size: 2.6rem;
  font-weight: 900;
  color: #ec7c08;
}

.header--contact .telBox .receptionTime {
  font-size: 1.4rem;
  color: #ec7c08;
}

.header--contact .telBox::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 28px;
  height: 28px;
  background: url(../img/icon/icon_tel_orange.png) no-repeat center center;
  background-size: contain;
  animation: vibrate02 2.5s infinite;
}

@media screen and (min-width: 1024px) {
  .header--contact .telBox:hover .num {
    color: #006899;
  }
  .header--contact .telBox:hover .receptionTime {
    color: #006899;
  }
  .header--contact .telBox:hover::before {
    background: url(../img/icon/icon_tel_blue.png) no-repeat center center;
    background-size: contain;
    animation: vibrate .5s 1;
  }
}

.header--contact .mailBox {
  margin-top: 35px;
}

.header--contact .mailBox a {
  font-size: 1.7rem;
  color: #fff;
  display: block;
  padding: 15px 0px;
  background: #fb522c;
  text-align: center;
  border-radius: 3px;
  max-width: 95%;
  margin: 0 auto;
}

.header--contact .mailBox a::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 22px;
  height: 17px;
  background: url(../img/icon/icon_mail_white.png) no-repeat center center;
  background-size: contain;
  margin-right: 10px;
}

@media screen and (min-width: 1024px) {
  .header--contact .mailBox:hover a {
    background: #006899;
  }
}

@media screen and (max-width: 1480px) {
  .header__other .snsList {
    left: 85%;
  }
}

@media screen and (max-width: 1024px) {
  /* =================================
    header
  ================================= */
  .header {
    width: 100%;
    height: auto;
    height: initial;
    background: rgba(255, 255, 255, 0.8);
  }
  .headerInner {
    max-height: 90vh;
    width: 80%;
    padding: 0;
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 220;
    box-shadow: 0px 0px 65px 0px rgba(1, 44, 64, 0.3);
  }
  .headerInner .btn--headerClose {
    width: 37px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 230;
  }
  .header .logo--sub {
    padding: 10px 0 10px 5%;
  }
  .header .logo--sub img {
    width: 112px;
  }
  .header__top {
    background: url(../img/bg_headerHead.jpg) no-repeat center center;
    background-size: cover;
    padding: 35px 24px 50px;
  }
  .header__top .ttl--site {
    text-align: left;
  }
  .header__top .ttl--site a {
    max-width: 125px;
    display: inline-block;
    pointer-events: none;
  }
  .header__top .ttl--site a img {
    display: block;
  }
  .header__top .ttl--site__sub {
    font-size: 1.2rem;
    text-align: left;
    margin: 8px 0 0;
  }
  .header__other {
    background: #fff;
    padding: 33px 0;
    position: relative;
  }
  .header__other .snsList {
    position: absolute;
    top: -18px;
    left: 50%;
    transform: translateX(-50%);
  }
  .header__other .snsList__item {
    width: 37px;
  }
  .header__other .telBox {
    text-align: center;
  }
  .header__other .telBox .num {
    font-size: 2.6rem;
    font-weight: bold;
    font-family: "Nunito";
  }
  .header__other .telBox .num::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    background: url(../img/icon/icon_tel_blue.png) no-repeat center center;
    background-size: contain;
    margin-right: 5px;
  }
  .header__other .telBox .receptionTime {
    font-size: 1.2rem;
    color: #000;
    margin: 10px 0 0;
  }
  .gNav .gNav__list {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    background: #fff;
    border-top: 1px solid #c0c0c0;
  }
  .gNav .gNav__list__item {
    width: 50%;
  }
  .gNav .gNav__list__item a {
    font-size: 1.5rem;
    padding: 22px 0 22px 18px;
    background: #fff;
    border-bottom: 1px solid #c0c0c0;
  }
  .gNav .gNav__list__item a::before {
    width: 15px;
    height: 15px;
    left: initial;
    right: 12px;
    border-radius: 50%;
    background: #b5e1f6;
  }
  .gNav .gNav__list__item a::after {
    width: 9px;
    height: 9px;
    background: #0974a7;
    left: initial;
    right: 15px;
    border-radius: 50%;
  }
  .gNav .gNav__list__item + .gNav__list__item {
    margin-top: 0;
  }
  .gNav .gNav__list__item:nth-child(2n - 1) a {
    border-right: 1px solid #c0c0c0;
  }
  .gNav .gNav__list::after {
    content: "";
    width: 100%;
    height: 1px;
    background: #fff;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .headerNav__cover {
    height: 100vh;
    width: 100vw;
    display: none;
    background: rgba(255, 255, 255, 0.7);
    position: absolute;
    top: 0;
    z-index: 210;
  }
  /*バーガーボタン設定*/
  .burger {
    width: 60px;
    height: 50px;
    background: #2590bd;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    z-index: 205;
  }
  .burger span {
    width: 21px;
    height: 2px;
    display: block;
    background: #fff;
    border-radius: 50px;
    position: absolute;
    top: 20px;
    right: 0;
    left: 0;
    margin: 0 auto;
    transition: width 0.2s, right 0.2s, left 0.2s;
  }
  .burger span.burger--top {
    transform: translateY(-5px);
  }
  .burger span.burger--middle {
    transform: translateY(0px);
    position: relative;
    background: none;
  }
  .burger span.burger--middle::before {
    content: "";
    display: block;
    position: absolute;
    width: 21px;
    height: 2px;
    border-radius: 50px;
    background: #fff;
    transition: all 0.2s;
    transform: rotate(0deg);
  }
  .burger span.burger--middle::after {
    content: "";
    display: block;
    position: absolute;
    width: 21px;
    height: 2px;
    border-radius: 50px;
    background: #fff;
    transition: all 0.2s;
    transform: rotate(0deg);
  }
  .burger span.burger--bottom {
    transform: translateY(5px);
  }
  .burger::after {
    content: "メニュー";
    font-size: 1rem;
    color: #fff;
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
  }
  .header--contact {
    width: auto;
    width: initial;
    margin-top: 0;
    padding: 0;
    position: fixed;
    top: 0;
    bottom: initial;
    left: initial;
    right: 60px;
    display: flex;
  }
  .header--contact .telBox {
    padding: 8px 0 7px;
    width: 60px;
    height: 50px;
    text-align: center;
    background: #ec7c08;
    border-right: 1px solid #fff;
  }
  .header--contact .telBox .num {
    font-size: 1rem;
    color: #fff;
  }
  .header--contact .telBox::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 18px;
    height: 18px;
    background: url(../img/icon/icon_tel_white.png) no-repeat center center;
    background-size: contain;
    position: static;
    transform: translate(0, 0);
    margin-bottom: 5px;
  }
  .header--contact .mailBox {
    margin-top: 0;
    width: 60px;
    height: 50px;
    text-align: center;
    border-right: 1px solid #fff;
  }
  .header--contact .mailBox a {
    font-size: 1rem;
    padding: 10px 0 5px;
    border-radius: 0;
    max-width: 100%;
  }
  .header--contact .mailBox a::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 19px;
    height: 15px;
    margin: 0 auto 5px;
    display: block;
  }
}

/*=================================
  footer
=================================*/
.footer {
  padding: 40px 44px;
}

.footer .footerCont {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-items: center;
}

.footer .footerCont .fNav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.footer .footerCont .fNav__item a {
  font-size: 1.4rem;
  color: #999;
  display: block;
}

@media screen and (min-width: 1024px) {
  .footer .footerCont .fNav__item a:hover {
    text-decoration: underline;
  }
}

.footer .footerCont .fNav__item + .fNav__item {
  margin-left: 20px;
}

.footer .footerCont .copy {
  font-size: 1.4rem;
  line-height: 1;
  color: #999;
  display: block;
}

@media screen and (max-width: 1260px) {
  /*=================================
    footer
  =================================*/
  .footer {
    padding: 20px 5%;
  }
  .footer .footerCont .fNav__item a {
    font-size: 1.2rem;
  }
  .footer .footerCont .fNav__item + .fNav__item {
    margin-left: 20px;
  }
  .footer .footerCont .copy {
    font-size: 1rem;
  }
}

/* =================================
  cv banner
================================= */
.cvItem--01 {
  padding: 65px 1% 60px;
  position: relative;
}

.cvItem--01.orange {
  background: url(../img/bg_cvItem01.jpg) no-repeat center center;
  background-size: cover;
}

.cvItem--01.orange .telWrap {
  background: #fff;
}

.cvItem--01.orange .telWrap .num {
  color: #ec7c08;
}

.cvItem--01.orange .telWrap .num::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 36px;
  height: 36px;
  background: url(../img/icon/icon_tel_orange.png) no-repeat center center;
  background-size: contain;
  margin: 5px 10px 0 0;
  animation: vibrate02 2.5s infinite;
}

.cvItem--01.orange .telWrap .receptionTime {
  color: #ec7c08;
}

@media screen and (min-width: 1024px) {
  .cvItem--01.orange .telWrap:hover {
    background: #006899;
  }
  .cvItem--01.orange .telWrap:hover .num::before {
    background: url(../img/icon/icon_tel_white.png) no-repeat center center;
    background-size: contain;
    animation: vibrate .5s 1;
  }
}

.cvItem--01.orange .mailWrap a {
  color: #fff;
  background: #ff2d2d;
}

.cvItem--01.orange .mailWrap a::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 34px;
  height: 26px;
  background: url(../img/icon/icon_mail_white.png) no-repeat center center;
  background-size: contain;
  margin-right: 13px;
}

@media screen and (min-width: 1024px) {
  .cvItem--01.orange .mailWrap a:hover {
    background: #006899;
  }
  .cvItem--01.orange .mailWrap a:hover::before {
    animation: vibrate .5s 1;
  }
}

.cvItem--01.blue {
  background: url(../img/bg_cvItem02.jpg) no-repeat center center;
  background-size: cover;
}

.cvItem--01.blue .telWrap {
  background: #e5f6fe;
}

.cvItem--01.blue .telWrap .num {
  color: #14191d;
}

.cvItem--01.blue .telWrap .receptionTime {
  color: #14191d;
}

@media screen and (min-width: 1024px) {
  .cvItem--01.blue .telWrap:hover {
    background: #ec7c08;
  }
}

.cvItem--01.blue .mailWrap a {
  color: #006899;
  background: #fff;
}

@media screen and (min-width: 1024px) {
  .cvItem--01.blue .mailWrap a:hover {
    background: #ec7c08;
  }
}

.cvItem--01__inner {
  max-width: 694px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.cvItem--01__inner .cvTtl {
  font-size: 3.6rem;
  font-weight: 900;
  color: #fff;
  text-align: center;
}

.cvItem--01__inner .text {
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;
  color: #fff;
  line-height: 1.875;
  margin: 20px 0 0;
}

.cvItem--01__inner .telWrap {
  text-align: center;
  padding: 10px 35px 10px 23px;
  border-radius: 3px;
}

.cvItem--01__inner .telWrap .num {
  font-size: 3.5rem;
  font-weight: 900;
}

.cvItem--01__inner .telWrap .receptionTime {
  font-size: 1.5rem;
}

@media screen and (min-width: 1024px) {
  .cvItem--01__inner .telWrap:hover .num {
    color: #fff;
  }
  .cvItem--01__inner .telWrap:hover .receptionTime {
    color: #fff;
  }
}

.cvItem--01__inner .mailWrap a {
  font-size: 2.4rem;
  font-weight: 900;
  display: block;
  padding: 27px 23px;
  border-radius: 3px;
}

@media screen and (min-width: 1024px) {
  .cvItem--01__inner .mailWrap:hover a {
    color: #fff;
  }
}

.cvItem--02 {
  background: url(../img/bg_cvItem03.jpg) no-repeat center center;
  background-size: cover;
  padding: 65px 1% 60px;
}

.cvItem--02__inner .cvTtl {
  font-size: 3.6rem;
  font-weight: 900;
  color: #fff;
  text-align: center;
}

.cvItem--02__inner .text {
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;
  color: #fff;
  line-height: 1.875;
  margin: 20px 0 0;
}

.cvItem--02__inner .mailWrap {
  max-width: 476px;
  margin: 0 auto;
}

.cvItem--02__inner .mailWrap a {
  font-size: 2.6rem;
  font-weight: 900;
  color: #fff;
  display: block;
  padding: 27px 23px;
  background: #ff2d2d;
  border-radius: 3px;
}

.cvItem--02__inner .mailWrap a::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 34px;
  height: 26px;
  background: url(../img/icon/icon_mail_white.png) no-repeat center center;
  background-size: contain;
  margin-right: 13px;
}

@media screen and (min-width: 1024px) {
  .cvItem--02__inner .mailWrap a:hover {
    background: #fff;
    color: #ec7c08;
    opacity: 1;
  }
  .cvItem--02__inner .mailWrap a:hover::before {
    background: url(../img/icon/icon_mail_orange.png) no-repeat center center;
    background-size: contain;
    margin-right: 13px;
    animation: vibrate .5s 1;
  }
}

/* =================================
  toppage
================================= */
.topPage .ttl--topMain {
  text-align: center;
  padding: 58px 1% 70px;
}

.topPage .ttl--topMain__ttl {
  font-size: 3.2rem;
  font-weight: 900;
  line-height: 1.25;
}

.topPage .ttl--topMain__ttl.web::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 31px;
  background: url(../img/icon/icon_web_blue.png) no-repeat center center;
  background-size: contain;
  margin: 0 20px 5px 0;
}

.topPage .ttl--topMain__ttl.media::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 23px;
  background: url(../img/icon/icon_media_black.png) no-repeat center center;
  background-size: contain;
  margin: 0 15px 0 0;
}

.topPage .ttl--topMain__ttl.info::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 21px;
  height: 30px;
  background: url(../img/icon/icon_info_blue.png) no-repeat center center;
  background-size: contain;
  margin: 0 17px 0 0;
}

.topPage .ttl--topMain__ttl.service::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 21px;
  height: 30px;
  background: url(../img/icon/icon_service_black.png) no-repeat center center;
  background-size: contain;
  margin: 0 16px 0 0;
}

.topPage .ttl--topMain__ttl.works::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 21px;
  background: url(../img/icon/icon_works_white.png) no-repeat center center;
  background-size: contain;
  margin: 0 12px 0 0;
}

.topPage .ttl--topMain__ttl.column::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 26px;
  height: 30px;
  background: url(../img/icon/icon_column_blue.png) no-repeat center center;
  background-size: contain;
  margin: 0 14px 0 0;
}

.topPage .ttl--topMain__ttl.outline::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 28px;
  height: 30px;
  background: url(../img/icon/icon_outline_blue.png) no-repeat center center;
  background-size: contain;
  margin: 0 5px 0 0;
}

.topPage .ttl--topMain__ttl.contact::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 29px;
  height: 22px;
  background: url(../img/icon/icon_contact_blue.png) no-repeat center center;
  background-size: contain;
  margin: 0 6px 0 0;
}

.topPage .ttl--topMain__text {
  font-size: 1.7rem;
  font-weight: 900;
  margin-top: 25px;
}

.topPage .ttl--topMain.blue .ttl--topMain__ttl {
  color: #006899;
}

.topPage .ttl--topMain.black .ttl--topMain__ttl {
  color: #14191d;
}

.topPage .ttl--topMain.white .ttl--topMain__ttl, .topPage .ttl--topMain.white .ttl--topMain__text {
  color: #fff;
}

.topPage .mv {
  background: url(../img/mv_bg.jpg) no-repeat center center;
  background-size: cover;
  padding: 148px 1% 130px;
  position: relative;
}

.topPage .mv__inner {
  max-width: 824px;
  margin: 0 auto;
}

.topPage .mv__inner .text--catch {
  text-align: center;
  position: relative;
}

.topPage .mv__inner .text--catch__main {
  margin-top: 108px;
}

.topPage .mv__inner .text--catch__sub {
  position: absolute;
  top: 0;
  left: 0;
}

.topPage .mv__inner .telWrap {
  font-weight: 900;
  background: #fff;
  border-radius: 50px;
  padding: 30px 38px 30px 43px;
  margin: 60px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}

.topPage .mv__inner .telWrap .text {
  font-size: 2rem;
  font-weight: 700;
}

.topPage .mv__inner .telWrap .text::before {
  content: "";
  width: 27px;
  height: 27px;
  display: inline-block;
  background: url(../img/icon/icon_tel_black.png) no-repeat center center;
  background-size: contain;
  vertical-align: middle;
  margin: 0 22px 0 0;
}

.topPage .mv__inner .telWrap .numWrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  line-height: 1;
}

.topPage .mv__inner .telWrap .numWrap .num {
  font-size: 3.6rem;
  color: #006899;
  margin-right: 30px;
}

.topPage .mv__inner .telWrap .numWrap .receptionTime {
  font-size: 1.2rem;
}

@media screen and (min-width: 1024px) {
  .topPage .mv__inner .telWrap:hover {
    background: #fb522c;
    color: #fff;
  }
  .topPage .mv__inner .telWrap:hover .num {
    color: #fff !important;
  }
}

.topPage .mv .scrollItem {
  position: absolute;
  right: 25px;
  bottom: 110px;
}

.topPage .mv .scrollItem .text {
  font-size: 1.4rem;
  font-weight: 900;
  color: #fff;
  writing-mode: vertical-rl;
  padding-left: 15px;
}

.topPage .mv .scrollItem::after {
  content: "";
  width: 2px;
  height: 0;
  display: inline-block;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-50%);
  animation: scroll 2s infinite ease-in-out;
}

.topPage .topSec.webConsul {
  background: #fff;
}

.topPage .topSec.webConsul .webConsul__cont {
  background: url(../img/bg_webConsul_pc.jpg) no-repeat top center;
  background-size: cover;
  min-height: 380px;
}

.topPage .topSec.webConsul .webConsul__cont .textWrap {
  background: #fff;
  width: 55%;
  margin: 0 0 0 auto;
  padding: 30px 40px 50px;
  position: relative;
}

.topPage .topSec.webConsul .webConsul__cont .textWrap::after {
  content: "";
  width: calc(100% - 80px);
  height: 2px;
  display: block;
  background: #006899;
  position: absolute;
  top: 45px;
  left: 40px;
}

.topPage .topSec.webConsul .webConsul__cont .textWrap__ttl {
  font-size: 2.7rem;
  color: #006899;
  margin: 0 0 25px;
  padding: 0 30px 0 0;
  display: inline-block;
  background: #fff;
  position: relative;
  z-index: 1;
}

.topPage .topSec.webConsul .webConsul__cont .textWrap__text {
  font-size: 1.6rem;
  line-height: 2.125;
  color: #181818;
}

.topPage .topSec.media {
  background: url(../img/bg_media.jpg) no-repeat center center;
  background-size: cover;
}

.topPage .topSec.media .media__cont .mediaList__item {
  padding: 0 0 140px 70px;
  position: relative;
}

.topPage .topSec.media .media__cont .mediaList__item .topItem {
  width: calc(100% - 350px);
  position: absolute;
  right: 0;
  bottom: 0;
}

.topPage .topSec.media .media__cont .mediaList__item .topItem img {
  margin: 0 0 0 auto;
}

.topPage .topSec.media .media__cont .mediaList__item .botItem {
  max-width: 410px;
}

.topPage .topSec.media .media__cont .mediaList__item .botItem .name {
  font-size: 3.4rem;
  font-weight: 900;
  color: #006899;
}

.topPage .topSec.media .media__cont .mediaList__item .botItem .text {
  line-height: 1.75;
  margin: 25px 0 0;
}

.topPage .topSec.media .media__cont .mediaList__item .botItem .btn {
  margin: 65px 0 0;
  display: block;
}

.topPage .topSec.media .media__cont .mediaList.slick-initialized .mediaList__item {
  padding-top: 80px;
}

.topPage .topSec.media .media__cont .mediaList .slick-dots li.slick-active button:before {
  background: #006899;
}

.topPage .topSec.info .infoList {
  max-width: 925px;
  margin: 0 auto;
  padding: 0 65px 68px;
}

.topPage .topSec.info .infoList__item {
  font-weight: 900;
  border: 1px solid #d7dcdd;
  border-radius: 3px;
  padding: 20px 22px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}

.topPage .topSec.info .infoList__item::after {
  content: "＞";
  color: #00a68d;
  position: absolute;
  top: 50%;
  right: 23px;
  transform: translateY(-50%);
}

.topPage .topSec.info .infoList__item .head {
  display: flex;
  align-items: center;
  margin-right: 18px;
}

.topPage .topSec.info .infoList__item .head .day {
  font-size: 1.4rem;
  margin-right: 24px;
}

.topPage .topSec.info .infoList__item .head .tag {
  font-size: 1.2rem;
  line-height: 1;
  color: #fff;
  padding: 5px 18px;
  border-radius: 3px;
}

.topPage .topSec.info .infoList__item .head .tag.blue {
  background: #267ea7;
}

.topPage .topSec.info .infoList__item .head .tag.green {
  background: #00a68d;
}

.topPage .topSec.info .infoList__item + .infoList__item {
  margin-top: 10px;
}

.topPage .topSec.service {
  background: #ddf3fe;
  /* Old browsers */
  background: -moz-linear-gradient(top, #ddf3fe 0%, #d0fbf5 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #ddf3fe 0%, #d0fbf5 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #ddf3fe 0%, #d0fbf5 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ddf3fe', endColorstr='#d0fbf5',GradientType=0 );
  /* IE6-9 */
  padding: 0 45px 70px;
}

.topPage .topSec.service .service__cont__block {
  max-width: 965px;
  margin: 0 auto;
  display: flex;
}

.topPage .topSec.service .service__cont__block.web .summary {
  background: url(../img/bg_serviceSum_web_pc.jpg) no-repeat center center;
  background-size: cover;
}

.topPage .topSec.service .service__cont__block.seo .summary {
  background: url(../img/bg_serviceSum_seo_pc.jpg) no-repeat center center;
  background-size: cover;
}

.topPage .topSec.service .service__cont__block.ad .summary {
  background: url(../img/bg_serviceSum_ad_pc.jpg) no-repeat center center;
  background-size: cover;
}

.topPage .topSec.service .service__cont__block .summary {
  color: #fff;
  width: 70%;
  padding: 5% 3% 3%;
}

.topPage .topSec.service .service__cont__block .summary__ttl {
  font-size: 2.2rem;
  font-weight: 900;
}

.topPage .topSec.service .service__cont__block .summary__text {
  font-size: 1.6rem;
  line-height: 1.875;
  margin-top: 22px;
}

.topPage .topSec.service .service__cont__block .serviceList {
  width: 30%;
  min-width: 370px;
}

.topPage .topSec.service .service__cont__block .serviceList__item {
  font-size: 2rem;
  font-weight: 900;
  color: #fff;
  width: 100%;
  padding: 34px 0 34px 85px;
  position: relative;
  background: #00a68d;
  /* Old browsers */
  background: -moz-linear-gradient(left, #00a68d 0%, #0070ba 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #00a68d 0%, #0070ba 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #00a68d 0%, #0070ba 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-green', endColorstr='#0070ba',GradientType=1 );
  /* IE6-9 */
}

.topPage .topSec.service .service__cont__block .serviceList__item a {
  font-size: 2rem;
  font-weight: 900;
  color: #fff;
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 1024px) {
  .topPage .topSec.service .service__cont__block .serviceList__item a:hover {
    color: #006899;
  }
}

.topPage .topSec.service .service__cont__block .serviceList__item .icon {
  width: 34px;
  position: absolute;
  top: 50%;
  left: -50px;
  transform: translateY(-50%);
}

.topPage .topSec.service .service__cont__block .serviceList__item:nth-child(2)::before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.1);
}

.topPage .topSec.service .service__cont__block .serviceList__item:nth-child(3)::before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.2);
}

.topPage .topSec.service .service__cont__block + .service__cont__block {
  margin-top: 20px;
}

.topPage .topSec.works {
  background: #3489b0;
  padding: 0 30px 80px;
}

.topPage .topSec.works .works__cont {
  max-width: 996px;
  margin: 0 auto;
}

.topPage .topSec.works .works__cont .worksList {
  display: flex;
  justify-content: space-between;
}

.topPage .topSec.works .works__cont .worksList__item {
  width: calc((100% - 50px) / 3);
}

.topPage .topSec.works .works__cont .worksList__item .botItem {
  padding: 25px 15px;
  background: #fff;
}

.topPage .topSec.works .works__cont .worksList__item .botItem .name {
  font-size: 2rem;
  font-weight: 900;
  color: #267ea7;
}

.topPage .topSec.works .works__cont .worksList__item .botItem .name::after {
  content: "＞";
  display: inline-block;
  margin-left: 18px;
}

.topPage .topSec.works .works__cont .worksList__item .botItem .text {
  font-size: 1.4rem;
  line-height: 1.85714;
  margin-top: 15px;
}

.topPage .topSec.works .works__cont .worksList.slick-initialized .worksList__item {
  width: auto;
  width: initial;
  margin: 0 12px;
}

.topPage .topSec.column .articleListWrap {
  max-width: 1022px;
  margin: 0 auto;
  padding: 0 30px;
}

.topPage .topSec.outline .outline__cont .gmapWrap__inner {
  position: relative;
  padding-bottom: 37%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.topPage .topSec.outline .outline__cont .gmapWrap iframe, .topPage .topSec.outline .outline__cont .gmapWrap object, .topPage .topSec.outline .outline__cont .gmapWrap embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.topPage .topSec.outline .outline__cont .accessInfo table {
  border: none;
  margin: 0;
}

.topPage .topSec.outline .outline__cont .accessInfo table th, .topPage .topSec.outline .outline__cont .accessInfo table td {
  border: none;
}

.topPage .topSec.outline .outline__cont .accessInfo table th {
  background: #fff;
  box-sizing: content-box;
  padding-left: 135px;
}

.topPage .topSec.outline .outline__cont .accessInfo table td {
  padding-right: 135px;
}

.topPage .topSec.outline .outline__cont .accessInfo table tr:nth-child(2n) th, .topPage .topSec.outline .outline__cont .accessInfo table tr:nth-child(2n) td {
  background: #f8f9fa !important;
}

.topPage .topSec.contact {
  padding: 40px 1% 100px;
}

.topPage .topSec.contact .contact__cont {
  max-width: 558px;
  margin: 0 auto;
}

.topPage .topSec.contact .contact__cont .contactForm table {
  width: 100%;
  border: none;
  margin: 0;
}

.topPage .topSec.contact .contact__cont .contactForm th {
  border: none;
  background: none;
  vertical-align: top;
  position: relative;
}

.topPage .topSec.contact .contact__cont .contactForm th::before {
  content: "";
  width: 2px;
  height: calc(100% - 30px);
  background: #006899;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.topPage .topSec.contact .contact__cont .contactForm th .required {
  font-size: 1.2rem;
  font-weight: normal;
  line-height: 1;
  display: block;
  margin-top: 10px;
  color: #fff;
  background: #ec7c08;
  padding: 5px 0;
  width: 4em;
  text-align: center;
}

.topPage .topSec.contact .contact__cont .contactForm td {
  border: none;
}

.topPage .topSec.contact .contact__cont .contactForm td input, .topPage .topSec.contact .contact__cont .contactForm td textarea {
  background: #f9f9f9;
  border: 2px solid #dcdcdc;
  border-radius: 3px;
  width: 100%;
  padding: 13px 14px;
}

.topPage .topSec.contact .contact__cont .contactForm td select {
  background: #f9f9f9;
  border: 2px solid #dcdcdc;
  border-radius: 3px;
  padding: 13px 14px;
}

.topPage .topSec.contact .contact__cont .contactForm td input[type="radio"], .topPage .topSec.contact .contact__cont .contactForm td input[type="checkbox"] {
  margin-right: 5px;
  width: auto;
  width: initial;
  padding: 0;
}

.topPage .topSec.contact .contact__cont .contactForm td textarea {
  min-height: 114px;
}

.topPage .topSec.contact .contact__cont .contactForm td label + label {
  margin-left: 10px;
}

.topPage .topSec.contact .contact__cont .contactForm td .error {
  font-size: 1.4rem;
  font-weight: 700;
  color: #ff2d2d;
  margin: 10px 0 0;
  display: block;
}

.topPage .topSec.contact .contact__cont .contactForm td .note {
  font-size: 1.3rem;
  line-height: 1.5;
  display: block;
  margin: 10px 0 0;
}

.topPage .topSec.contact .contact__cont .contactForm td .note.red {
  color: #ff2d2d;
}

.topPage .topSec.contact .contact__cont .contactForm td .note + .note {
  margin: 5px 0 0;
}

.topPage .topSec.contact .contact__cont .contactForm .confirmingTxt {
  font-size: 1.4rem;
  text-align: center;
  margin: 37px 0;
}

.topPage .topSec.contact .contact__cont .contactForm .confirmingTxt input {
  -webkit-appearance: checkbox;
}

.topPage .topSec.contact .contact__cont .contactForm .confirmingTxt a {
  text-decoration: underline;
}

@media screen and (min-width: 1024px) {
  .topPage .topSec.contact .contact__cont .contactForm .confirmingTxt a:hover {
    text-decoration: none;
  }
}

.topPage .topSec.contact .contact__cont .completedForm .completedMessage {
  font-size: 1.8rem;
  font-weight: 900;
  line-height: 1;
  display: inline-block;
  margin: 24px 0 0;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.topPage .topSec.contact .contact__cont .completedForm .completedMessage::after {
  content: "";
  width: 100%;
  height: 3px;
  background: #006899;
  display: inline-block;
}

.topPage .topSec.contact .contact__cont .completedForm p {
  text-align: center;
  margin: 40px 0 0;
}

/* =================================
  下層共通
================================= */
.pagenation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 53px auto;
}

.pagenation .pager {
  background: #00a68d;
  /* Old browsers */
  background: -moz-linear-gradient(left, #00a68d 0%, #0070ba 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #00a68d 0%, #0070ba 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #00a68d 0%, #0070ba 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-green', endColorstr='#0070ba',GradientType=1 );
  /* IE6-9 */
}

.pagenation .pager li {
  display: inline-block;
}

.pagenation .pager li a {
  font-size: 1.6rem;
  font-weight: 900;
  color: #fff;
  width: 35px;
  line-height: 35px;
  text-align: center;
  display: block;
}

@media screen and (min-width: 1024px) {
  .pagenation .pager li a:hover {
    background: #006899;
  }
}

.pagenation .pager li span {
  font-size: 1.6rem;
  font-weight: 900;
  color: #fff;
  width: 35px;
  line-height: 35px;
  text-align: center;
  display: block;
}

.pagenation .pager li + li {
  position: relative;
}

.pagenation .pager li + li::before {
  content: "";
  width: 2px;
  height: 100%;
  background: #fff;
  display: block;
  position: absolute;
  top: 0;
  left: -2px;
}

.under .articleListWrap {
  max-width: 1022px;
  margin: 0 auto;
  padding: 35px 30px 40px;
}

.under .articleTtlWrap {
  background: url(../img/ttl_bg_articleList.jpg) no-repeat center center;
  background-size: cover;
  text-align: center;
  padding: 95px 1% 118px;
}

.under .articleTtlWrap .pageTtl {
  font-size: 5rem;
  font-weight: 900;
  color: #fff;
}

.under .articleTtlWrap .pageTtl::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 33px;
  height: 38px;
  background: url(../img/icon/icon_column_white.png) no-repeat center center;
  background-size: contain;
  margin: -10px 13px 0 0;
}

.under .articleTtlWrap .summary {
  font-size: 1.7rem;
  font-weight: 900;
  color: #fff;
  margin: 15px 0 0;
}

.under .category {
  padding: 68px 30px 30px;
  background: #ddf3fe;
  /* Old browsers */
  background: -moz-linear-gradient(top, #ddf3fe 0%, #d0fbf5 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #ddf3fe 0%, #d0fbf5 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #ddf3fe 0%, #d0fbf5 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ddf3fe', endColorstr='#d0fbf5',GradientType=0 );
  /* IE6-9 */
}

.under .category .ttl--category {
  text-align: center;
  margin: 0 0 60px;
}

.under .category .ttl--category__ttl {
  font-size: 3.2rem;
  font-weight: 900;
}

.under .category .ttl--category__ttl::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 21px;
  height: 30px;
  background: url(../img/icon/icon_service_black.png) no-repeat center center;
  background-size: contain;
  margin: 0 16px 0 0;
}

.under .category .ttl--category__text {
  font-size: 1.7rem;
  font-weight: 900;
  margin: 20px 0 0;
}

.under .category .categoryList {
  max-width: 1022px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  background: #00a68d;
  /* Old browsers */
  background: -moz-linear-gradient(left, #00a68d 0%, #0070ba 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #00a68d 0%, #0070ba 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #00a68d 0%, #0070ba 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-green', endColorstr='#0070ba',GradientType=1 );
  /* IE6-9 */
}

.under .category .categoryList__item {
  width: calc(100% / 3);
  position: relative;
}

.under .category .categoryList__item a {
  font-size: 1.8rem;
  font-weight: 900;
  color: #fff;
  display: block;
  padding: 27px 5px 27px 66px;
  position: relative;
  z-index: 1;
}

.under .category .categoryList__item .icon {
  width: 34px;
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}

.under .category .categoryList__item::after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  opacity: 0;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}

@media screen and (min-width: 1024px) {
  .under .category .categoryList__item:hover::after {
    opacity: .2;
  }
}

.under .category .categoryList__item:nth-child(n + 4) {
  border-top: 1px solid #fff;
}

.under .category .categoryList__item:not(:nth-child(3n + 1)) {
  border-left: 1px solid #fff;
}

/* =================================
  article list
================================= */
.articleList__item {
  display: flex;
  align-items: flex-start;
  background: #fff;
  box-shadow: 0px 0px 65px 0px rgba(1, 51, 74, 0.1);
}

.articleList__item .topItem {
  width: 467px;
  padding: 0 0 50px;
  position: relative;
}

.articleList__item .topItem .thumb {
  width: 100%;
  height: auto;
}

.articleList__item .topItem .day {
  width: 90px;
  height: 90px;
  background: rgba(0, 104, 153, 0.9);
  border-radius: 50px;
  text-align: center;
  position: absolute;
  left: 20px;
  bottom: 5px;
  z-index: 6;
}

.articleList__item .topItem .day .num {
  font-size: 2rem;
  line-height: 1.3;
  color: #fff;
  display: block;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.articleList__item .botItem {
  width: calc(100% - 267px);
  padding: 30px;
}

.articleList__item .botItem .ttl {
  font-size: 2.2rem;
  font-weight: 900;
  line-height: 1.27272;
  color: #006899;
}

.articleList__item .botItem .text {
  font-size: 1.4rem;
  line-height: 1.71428;
  margin: 25px 0 0;
}

.articleList__item .botItem .info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 35px 0 0;
}

.articleList__item .botItem .info .tag {
  display: flex;
  flex-wrap: wrap;
}

.articleList__item .botItem .info .tag__item {
  margin-right: 14px;
}

.articleList__item .botItem .info .tag__item > * {
  font-size: 1.4rem;
  color: #006899;
}

.articleList__item .botItem .info .tag__item > *::before {
  content: "#";
  display: inline-block;
}

.articleList__item .botItem .info .view {
  font-size: 1.8rem;
  font-weight: 900;
  color: #e4488d;
}

.articleList__item .botItem .info .view::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 19px;
  height: 19px;
  background: url(../img/icon/icon_favo.png) no-repeat center center;
  background-size: contain;
  margin: -3px 5px 0 0;
}

.articleList__item + .articleList__item {
  margin: 30px 0 0;
}

/* =================================
  articleDetail
================================= */
.articleDetail .breadcrumb {
  width: 100%;
  padding: 35px 38px 35px;
  background: url(../img/articleMv_deco.png) no-repeat bottom center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.articleDetail .breadcrumb ul {
  display: flex;
  align-items: center;
}

.articleDetail .breadcrumb ul li {
  color: #fff;
}

.articleDetail .breadcrumb ul li a {
  color: #fff;
}

/* =================================
  singlePage
================================= */
.singlePage .tocBox {
  margin: 40px 0 50px -45px;
  width: calc(100% + 90px);
}

.singlePage .tocBox__ttl {
  font-size: 3.2rem;
  font-weight: 900;
  color: #006899;
  text-align: center;
}

.singlePage .tocBox__ttl::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 28px;
  height: 18px;
  background: url(../img/icon/icon_index.png) no-repeat center center;
  background-size: contain;
  margin: 0 14px 4px 0;
}

.singlePage .tocBox .tocList {
  counter-reset: tocNum;
  margin: 30px auto 0;
}

.singlePage .tocBox .tocList__item a {
  font-size: 1.6rem;
  font-weight: 900;
  display: block;
  background: #f8f9fa;
  padding: 18px 82px;
  position: relative;
}

.singlePage .tocBox .tocList__item a::before {
  counter-increment: tocNum;
  content: "0" counter(tocNum);
  position: absolute;
  left: 35px;
}

.singlePage .tocBox .tocList__item a::after {
  content: "＞";
  font-size: 1.6rem;
  color: #00a68d;
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translateY(-50%) rotate(90deg);
}

@media screen and (min-width: 1024px) {
  .singlePage .tocBox .tocList__item a:hover {
    text-decoration: underline;
  }
  .singlePage .tocBox .tocList__item a:hover::before {
    text-decoration: none !important;
  }
  .singlePage .tocBox .tocList__item a:hover::after {
    text-decoration: none !important;
  }
}

.singlePage .tocBox .tocList__item:nth-child(2n) a {
  background: #fff;
}

.singlePage .articleMv {
  width: 100%;
  position: relative;
}

.singlePage .articleMv .img {
  width: 100%;
  height: auto;
}

.singlePage .data {
  margin: -59px 0 0;
  padding: 0 40px;
  display: flex;
  align-items: flex-end;
}

.singlePage .data .date {
  width: 117px;
  height: 117px;
  text-align: center;
  background: rgba(0, 104, 153, 0.9);
  border-radius: 50%;
  position: relative;
}

.singlePage .data .date .num {
  font-size: 2.6rem;
  line-height: 1.3;
  color: #fff;
  width: 100%;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.singlePage .data .info {
  margin-left: 15px;
  width: calc(100% - 117px);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.singlePage .data .info .tag {
  display: flex;
}

.singlePage .data .info .tag__item {
  margin-right: 15px;
}

.singlePage .data .info .tag__item > * {
  font-size: 1.6rem;
  color: #006899;
}

.singlePage .data .info .tag__item > *::before {
  content: "#";
}

.singlePage .data .info .social {
  display: flex;
}

.singlePage .data .info .social__item .icon {
  width: 23px;
  margin: 0 7px 3px 0;
}

.singlePage .data .info .social__item .count {
  font-size: 2rem;
  font-weight: 900;
}

.singlePage .data .info .social__item.fb {
  color: #4576e3;
}

.singlePage .data .info .social__item.tw {
  color: #55b9f3;
}

.singlePage .data .info .social__item.view {
  color: #e4488d;
}

.singlePage .data .info .social__item + .social__item {
  margin-left: 30px;
}

.singlePage__in {
  max-width: 1022px;
  margin: 0 auto;
  background: #fff;
  padding: 30px 45px 80px;
}

.singlePage__in > .caption {
  font-size: 1.6rem;
  margin: 20px 0 0 -15px;
  width: calc(100% + 30px);
}

.singlePage__in > h1 {
  font-size: 3.2rem;
  color: #006899;
  line-height: 1.5;
  margin: 20px 0 20px -15px;
  width: calc(100% + 30px);
}

.singlePage__in > h2 {
  font-size: 2.8rem;
  line-height: 1.5;
  color: #fff;
  background: #3489b0;
  padding: 25px 28px;
  margin: 50px 0 40px;
}

.singlePage__in > h3 {
  font-size: 2.4rem;
  line-height: 1.25;
  border-left: 7px solid #267ea7;
  padding: 2px 20px 4px;
  margin: 40px 0 30px;
  position: relative;
}

.singlePage__in > h3::after {
  content: "";
  width: 100%;
  height: 1px;
  background: #d0d0d0;
  display: block;
  position: absolute;
  left: -7px;
  bottom: -7px;
}

.singlePage__in > h4 {
  font-size: 2rem;
  color: #006899;
  line-height: 1.25;
  padding: 13px 17px;
  margin: 30px 0 24px;
  background: #e3f4fb;
}

.singlePage__in > h5 {
  font-size: 1.6rem;
  line-height: 1.5;
  color: #006899;
  margin: 30px 0 24px;
}

.singlePage__in > p {
  line-height: 1.75;
}

.singlePage__in > p + p {
  margin-top: 35px;
}

.singlePage__in > img {
  display: block;
  margin: 0 auto 40px;
}

.singlePage__in > figure {
  text-align: center;
  margin: 0 auto 40px;
}

.singlePage__in > figure figcaption {
  font-size: 1.4rem;
  color: #181818;
  margin: 30px 0 0;
}

.singlePage__in > table {
  margin: 40px 0 30px;
}

.singlePage__in > ul:not([class]) {
  padding: 25px 30px;
  margin: 25px auto;
  background: #e9f6fb;
}

.singlePage__in > ul:not([class]) li {
  font-size: 1.6rem;
  font-weight: 900;
  line-height: 1.75;
  color: #006899;
  padding-left: 20px;
  position: relative;
}

.singlePage__in > ul:not([class]) li::before {
  content: "・";
  color: #006899;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.singlePage__in > ul:not([class]) li + li {
  margin: 15px 0 0;
}

.singlePage__in > ol:not([class]) {
  counter-reset: counter;
  padding: 25px 30px;
  margin: 25px auto;
  background: #f8f9fa;
}

.singlePage__in > ol:not([class]) li {
  font-size: 1.6rem;
  font-weight: 900;
  line-height: 1.75;
  padding-left: 30px;
  position: relative;
}

.singlePage__in > ol:not([class]) li::before {
  counter-increment: counter;
  content: "0" counter(counter) ".";
  font-size: 1.6rem;
  font-weight: 900;
  line-height: 1;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 5px;
}

.singlePage__in > ol:not([class]) li + li {
  margin: 15px 0 0;
}

.singlePage__in > blockquote {
  position: relative;
  padding: 5px 12px 5px 56px;
  margin: 20px 0;
  box-sizing: border-box;
  font-style: italic;
  color: #464646;
  background: #f8fafb;
}

.singlePage__in > blockquote::before {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 10px;
  width: 40px;
  height: 40px;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  vertical-align: middle;
  text-align: center;
  content: "“";
  color: #fff;
  font-size: 5rem;
  line-height: 1.2;
  text-indent: -0.3em;
  background: #006899;
  border-radius: 50%;
}

.singlePage__in > blockquote p {
  position: relative;
  padding: 0;
  margin: 10px 0;
  z-index: 3;
  line-height: 1.7;
}

.singlePage__in > blockquote cite {
  display: block;
  text-align: right;
  line-height: 1;
  color: #888888;
  font-size: 0.9em;
  margin: 10px 0 0;
}

.singlePage .pushBox {
  background: #fff;
  border: 6px solid #ddf3fe;
  padding: 30px;
  margin: 50px auto 80px;
  position: relative;
}

.singlePage .pushBox .label {
  font-size: 1.2rem;
  font-weight: 900;
  color: #fff;
  background: #267ea7;
  padding: 6px 13px;
  margin: 0 0 13px;
  display: inline-block;
  border-radius: 3px;
}

.singlePage .pushBox__cont {
  display: flex;
  align-items: flex-start;
  flex-direction: row-reverse;
}

.singlePage .pushBox__cont .topItem {
  width: 328px;
  margin-left: 30px;
}

.singlePage .pushBox__cont .topItem .thumb {
  width: 100%;
  height: auto;
}

.singlePage .pushBox__cont .botItem {
  width: calc(100% - 358px);
}

.singlePage .pushBox__cont .botItem .ttl {
  font-size: 2rem;
  font-weight: 900;
  color: #267ea7;
}

.singlePage .pushBox__cont .botItem .text {
  font-size: 1.4rem;
  line-height: 1.85714;
  margin: 17px 0 0;
}

.singlePage .col2Box {
  display: flex;
  align-items: flex-start;
  box-shadow: 0px 0px 65px 0px rgba(1, 51, 74, 0.1);
  background: #fff;
  margin: 40px auto 50px;
}

.singlePage .col2Box .topItem {
  width: 50%;
}

.singlePage .col2Box .botItem {
  width: 50%;
  font-size: 1.6rem;
  line-height: 2.25;
  padding: 34px;
}

.relatedBox {
  max-width: 1022px;
  margin: 0 auto;
  padding: 80px 30px;
}

.relatedBox .ttl--related {
  text-align: center;
  margin: 0 0 65px;
}

.relatedBox .ttl--related__ttl {
  font-size: 3.2rem;
  font-weight: 900;
  color: #006899;
}

.relatedBox .ttl--related__ttl::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 26px;
  height: 30px;
  background: url(../img/icon/icon_column_blue.png) no-repeat center center;
  background-size: contain;
  margin: 0 12px 5px 0;
}

.relatedBox .ttl--related__text {
  font-size: 1.7rem;
  font-weight: 900;
  margin: 20px 0 0;
}

@media screen and (max-width: 1354px) {
  .topPage .mv__inner {
    text-align: center;
  }
  .topPage .mv__inner .telWrap {
    text-align: center;
    display: inline-block;
    padding: 13px 35px 13px 43px;
  }
  .topPage .mv__inner .telWrap .numWrap {
    display: block;
  }
}

@media screen and (max-width: 1184px) {
  /* =================================
    toppage
  ================================= */
  .topPage .topSec.service {
    padding: 0 5% 42px;
  }
  .topPage .topSec.service .service__cont__block {
    max-width: auto;
    max-width: initial;
    display: block;
  }
  .topPage .topSec.service .service__cont__block.web .summary {
    background: url(../img/bg_serviceSum_web_sp.jpg) no-repeat top center;
    background-size: cover;
  }
  .topPage .topSec.service .service__cont__block.seo .summary {
    background: url(../img/bg_serviceSum_seo_sp.jpg) no-repeat top center;
    background-size: cover;
  }
  .topPage .topSec.service .service__cont__block.ad .summary {
    background: url(../img/bg_serviceSum_ad_sp.jpg) no-repeat top center;
    background-size: cover;
  }
  .topPage .topSec.service .service__cont__block .summary {
    width: 100%;
    padding: 50px 5% 20px;
  }
  .topPage .topSec.service .service__cont__block .summary__ttl {
    font-size: 1.9rem;
  }
  .topPage .topSec.service .service__cont__block .summary__text {
    font-size: 1.2rem;
    line-height: 1.66666;
    margin-top: 15px;
  }
  .topPage .topSec.service .service__cont__block .serviceList {
    width: 100%;
    min-width: auto;
    min-width: initial;
  }
  .topPage .topSec.service .service__cont__block .serviceList__item {
    font-size: 1.7rem;
    width: 100%;
    padding: 20px 0 20px 55px;
  }
  .topPage .topSec.service .service__cont__block .serviceList__item a {
    font-size: 1.7rem;
  }
  .topPage .topSec.service .service__cont__block .serviceList__item .icon {
    width: 24px;
    left: -40px;
  }
}

@media screen and (max-width: 1024px) {
  /* =================================
    cv banner
  ================================= */
  .cvItem--01 {
    padding: 40px 5% 30px;
  }
  .cvItem--01.orange {
    background: url(../img/bg_cvItem01.jpg) no-repeat center center;
    background-size: cover;
  }
  .cvItem--01.orange .telWrap .num::before {
    width: 29px;
    height: 29px;
    margin: 3px 6px 0 0;
  }
  .cvItem--01.orange .mailWrap a::before {
    width: 28px;
    height: 21px;
    margin-right: 6px;
  }
  .cvItem--01__inner {
    max-width: auto;
    max-width: initial;
  }
  .cvItem--01__inner .cvTtl {
    font-size: 2.5rem;
  }
  .cvItem--01__inner .text {
    font-size: 1.2rem;
    line-height: 1.58333;
    margin: 15px 0 0;
  }
  .cvItem--01__inner .telWrap {
    padding: 15px 15px 15px 15px;
  }
  .cvItem--01__inner .telWrap .num {
    font-size: 2.9rem;
  }
  .cvItem--01__inner .telWrap .receptionTime {
    font-size: 1.3rem;
  }
  .cvItem--01__inner .mailWrap a {
    font-size: 2rem;
    padding: 20px 5%;
  }
  .cvItem--02 {
    padding: 40px 5% 30px;
  }
  .cvItem--02__inner .cvTtl {
    font-size: 2.5rem;
  }
  .cvItem--02__inner .text {
    font-size: 1.2rem;
    line-height: 1.58333;
    margin: 15px 0 0;
  }
  .cvItem--02__inner .mailWrap {
    max-width: auto;
    max-width: initial;
  }
  .cvItem--02__inner .mailWrap a {
    font-size: 2rem;
    padding: 25px 5%;
  }
  .cvItem--02__inner .mailWrap a::before {
    width: 28px;
    height: 21px;
    margin-right: 6px;
  }
  /* =================================
    toppage
  ================================= */
  .topPage .ttl--topMain {
    padding: 35px 0 25px;
  }
  .topPage .ttl--topMain__ttl {
    font-size: 2.5rem;
  }
  .topPage .ttl--topMain__ttl.web::before {
    width: 25px;
    height: 26px;
    margin: 0 7px 3px 0;
  }
  .topPage .ttl--topMain__ttl.media::before {
    width: 23px;
    height: 17px;
    margin: 0 8px 0 0;
  }
  .topPage .ttl--topMain__ttl.info::before {
    width: 18px;
    height: 26px;
    margin: 0 10px 0 0;
  }
  .topPage .ttl--topMain__ttl.service::before {
    width: 16px;
    height: 28px;
    margin: 0 5px 0 0;
  }
  .topPage .ttl--topMain__ttl.works::before {
    width: 24px;
    height: 17px;
    margin: 0 5px 0 0;
  }
  .topPage .ttl--topMain__ttl.column::before {
    width: 18px;
    height: 21px;
    margin: 0 5px 0 0;
  }
  .topPage .ttl--topMain__ttl.outline::before {
    width: 17px;
    height: 18px;
    margin: 0 5px 0 0;
  }
  .topPage .ttl--topMain__ttl.contact::before {
    width: 26px;
    height: 16px;
    margin: 0 5px 0 0;
  }
  .topPage .ttl--topMain__text {
    font-size: 1.3rem;
    margin-top: 10px;
  }
  .topPage .mv {
    margin-top: 50px;
    padding: 63px 5% 57px;
  }
  .topPage .mv__inner {
    max-width: auto;
    max-width: initial;
  }
  .topPage .mv__inner .text--catch__main {
    margin-top: 20px;
  }
  .topPage .mv__inner .text--catch__sub {
    position: static;
  }
  .topPage .mv__inner .telWrap {
    padding: 12px 10px;
    margin: 20px 0 0;
    display: block;
    text-align: center;
    position: relative;
  }
  .topPage .mv__inner .telWrap .text {
    font-size: 1.3rem;
  }
  .topPage .mv__inner .telWrap .text::before {
    content: none;
  }
  .topPage .mv__inner .telWrap .numWrap {
    display: block;
  }
  .topPage .mv__inner .telWrap .numWrap .num {
    font-size: 3rem;
    background: -webkit-linear-gradient(0deg, #00a68d, #006899);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 5px 0 0;
    display: block;
    position: relative;
  }
  .topPage .mv__inner .telWrap .numWrap .num::before {
    content: "";
    width: 23px;
    height: 23px;
    display: inline-block;
    background: url(../img/icon/icon_tel_grad.png) no-repeat center center;
    background-size: contain;
    margin-right: 10px;
  }
  .topPage .mv__inner .telWrap .numWrap .receptionTime {
    font-size: 1.1rem;
  }
  .topPage .topSec.webConsul .webConsul__cont {
    background: url(../img/bg_webConsul_sp.jpg) no-repeat top center;
    background-size: cover;
    min-height: auto;
    min-height: initial;
    padding: 0 10% 0;
  }
  .topPage .topSec.webConsul .webConsul__cont .textWrap {
    width: 100%;
    margin: 0 auto;
    padding: 40px 6% 45px;
    top: 70px;
    box-shadow: 0px 0px 65px 0px rgba(1, 44, 64, 0.3);
  }
  .topPage .topSec.webConsul .webConsul__cont .textWrap::after {
    width: calc(100% - 60px);
    top: 50px;
    left: 30px;
  }
  .topPage .topSec.webConsul .webConsul__cont .textWrap__ttl {
    font-size: 1.9rem;
    margin: 0 0 20px;
    padding: 0 15px 0 0;
  }
  .topPage .topSec.webConsul .webConsul__cont .textWrap__text {
    font-size: 1.2rem;
    line-height: 1.75;
  }
  .topPage .topSec.media {
    background: url(../img/bg_media_sp.jpg) repeat-y center top;
    background-size: cover;
    padding: 70px 5% 28px;
  }
  .topPage .topSec.media .media__cont .mediaList__item {
    padding: 0;
  }
  .topPage .topSec.media .media__cont .mediaList__item .topItem {
    width: 100%;
    margin: 0 0 18px;
    position: static;
  }
  .topPage .topSec.media .media__cont .mediaList__item .topItem img {
    margin: auto;
  }
  .topPage .topSec.media .media__cont .mediaList__item .botItem {
    max-width: auto;
    max-width: initial;
  }
  .topPage .topSec.media .media__cont .mediaList__item .botItem .name {
    font-size: 2rem;
    text-align: center;
  }
  .topPage .topSec.media .media__cont .mediaList__item .botItem .text {
    font-size: 1.3rem;
    line-height: 1.46153;
    margin: 14px 0 0;
  }
  .topPage .topSec.media .media__cont .mediaList__item .botItem .btn {
    margin: 20px auto 0;
    display: block;
    text-align: center;
  }
  .topPage .topSec.media .media__cont .mediaList.slick-initialized .mediaList__item {
    padding-top: 70px;
  }
  .topPage .topSec.info .infoList {
    max-width: auto;
    max-width: initial;
    padding: 0 5% 28px;
  }
  .topPage .topSec.info .infoList__item {
    padding: 10px 40px 10px 13px;
    display: block;
  }
  .topPage .topSec.info .infoList__item::after {
    right: 13px;
  }
  .topPage .topSec.info .infoList__item .head {
    margin: 0 0 10px;
  }
  .topPage .topSec.info .infoList__item .head .day {
    font-size: 1.2rem;
    margin-right: 10px;
  }
  .topPage .topSec.info .infoList__item .head .tag {
    font-size: 1.1rem;
    padding: 5px 10px;
  }
  .topPage .topSec.works {
    padding: 0 0 35px;
  }
  .topPage .topSec.works .works__cont {
    max-width: auto;
    max-width: initial;
  }
  .topPage .topSec.works .works__cont .worksList {
    display: block;
  }
  .topPage .topSec.works .works__cont .worksList__item {
    width: 90%;
    margin: 0 auto;
    background: #fff;
  }
  .topPage .topSec.works .works__cont .worksList__item .topItem .thumb {
    width: 100%;
  }
  .topPage .topSec.works .works__cont .worksList__item .botItem {
    padding: 25px 20px;
  }
  .topPage .topSec.works .works__cont .worksList__item .botItem .name {
    font-size: 1.8rem;
    padding-right: 18px;
    position: relative;
  }
  .topPage .topSec.works .works__cont .worksList__item .botItem .name::after {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .topPage .topSec.works .works__cont .worksList__item .botItem .text {
    font-size: 1.3rem;
  }
  .topPage .topSec.works .works__cont .worksList__item + .worksList__item {
    margin-top: 20px;
  }
  .topPage .topSec.works .works__cont .worksList.slick-initialized .worksList__item {
    width: 100%;
    width: auto;
    width: initial;
    margin: 70px 11px 0;
  }
  .topPage .topSec.works .works__cont .worksList.slick-initialized .slick-prev {
    top: 34%;
    left: 13%;
  }
  .topPage .topSec.works .works__cont .worksList.slick-initialized .slick-next {
    top: 34%;
    right: 13%;
  }
  .topPage .topSec.column {
    background: url(../img/bg.jpg) repeat center top;
    background-size: contain;
  }
  .topPage .topSec.column .articleListWrap {
    padding: 0 5% 32px;
  }
  .topPage .topSec.outline .outline__cont .accessInfo table {
    display: table;
    white-space: initial;
  }
  .topPage .topSec.outline .outline__cont .accessInfo table th {
    padding-left: 5%;
    width: 7em;
  }
  .topPage .topSec.outline .outline__cont .accessInfo table td {
    padding-right: 5%;
  }
  .topPage .topSec.contact {
    padding: 20px 5% 40px;
  }
  .topPage .topSec.contact .contact__cont {
    max-width: auto;
    max-width: initial;
  }
  .topPage .topSec.contact .contact__cont .contactForm table {
    white-space: initial;
  }
  .topPage .topSec.contact .contact__cont .contactForm tr {
    display: block;
  }
  .topPage .topSec.contact .contact__cont .contactForm th {
    display: block;
    width: 100%;
    padding: 5px 10px;
  }
  .topPage .topSec.contact .contact__cont .contactForm th::before {
    height: 100%;
  }
  .topPage .topSec.contact .contact__cont .contactForm th .required {
    display: inline-block;
    margin: 0 0 0 10px;
    color: #fff;
    background: #ec7c08;
    padding: 5px 0;
    width: 4em;
    text-align: center;
  }
  .topPage .topSec.contact .contact__cont .contactForm td {
    font-size: 1.6rem;
    padding: 10px 0 20px;
    width: 100%;
    display: block;
  }
  .topPage .topSec.contact .contact__cont .contactForm td input, .topPage .topSec.contact .contact__cont .contactForm td textarea {
    padding: 12px 12px;
  }
  .topPage .topSec.contact .contact__cont .contactForm td select {
    padding: 12px 12px;
  }
  .topPage .topSec.contact .contact__cont .contactForm td .error {
    font-size: 1.2rem;
    margin: 5px 0 0;
  }
  .topPage .topSec.contact .contact__cont .contactForm td .note {
    font-size: 1.2rem;
    line-height: 1.25;
    margin: 5px 0 0;
  }
  .topPage .topSec.contact .contact__cont .contactForm .confirmingTxt {
    font-size: 1.2rem;
    margin: 20px 0;
  }
  .topPage .topSec.contact .contact__cont .completedForm .completedMessage {
    font-size: 1.6rem;
    margin: 15px 0 0;
  }
  .topPage .topSec.contact .contact__cont .completedForm p {
    font-size: 1.4rem;
    margin: 20px 0 0;
  }
  /* =================================
    下層共通
  ================================= */
  .pagenation {
    margin: 40px auto;
  }
  .pagenation .pager li a {
    font-size: 1.4rem;
  }
  .pagenation .pager li span {
    font-size: 1.4rem;
  }
  .under .articleListWrap {
    padding: 30px 5% 20px;
  }
  .under .articleTtlWrap {
    padding: 45px 5% 50px;
  }
  .under .articleTtlWrap .pageTtl {
    font-size: 2.8rem;
  }
  .under .articleTtlWrap .pageTtl::before {
    width: 24px;
    height: 29px;
    margin: -10px 6px 0 0;
  }
  .under .articleTtlWrap .summary {
    font-size: 1.5rem;
    margin: 12px 0 0;
  }
  .under .category {
    padding: 45px 5% 30px;
  }
  .under .category .ttl--category {
    margin: 0 0 25px;
  }
  .under .category .ttl--category__ttl {
    font-size: 2.5rem;
  }
  .under .category .ttl--category__ttl::before {
    width: 16px;
    height: 28px;
    margin: 0 5px 0 0;
  }
  .under .category .ttl--category__text {
    font-size: 1.3rem;
    margin: 10px 0 0;
  }
  .under .category .categoryList__item {
    width: 100%;
  }
  .under .category .categoryList__item a {
    font-size: 1.2rem;
    padding: 15px 5% 15px 13%;
  }
  .under .category .categoryList__item .icon {
    width: 16px;
    left: 5%;
  }
  .under .category .categoryList__item:nth-child(n + 2) {
    border-top: 1px solid #fff;
  }
  .under .category .categoryList__item:not(:nth-child(3n + 1)) {
    border-left: none;
  }
  /* =================================
    article list
  ================================= */
  .articleList__item {
    display: block;
  }
  .articleList__item .topItem {
    width: 100%;
    padding: 0;
  }
  .articleList__item .topItem .day {
    width: 72px;
    height: 72px;
    left: 5%;
    bottom: -19px;
  }
  .articleList__item .topItem .day .num {
    font-size: 1.6rem;
    line-height: 1.28125;
  }
  .articleList__item .botItem {
    width: 100%;
    padding: 27px 5% 25px;
  }
  .articleList__item .botItem .ttl {
    font-size: 1.7rem;
  }
  .articleList__item .botItem .text {
    font-size: 1.2rem;
    line-height: 1.41666;
    margin: 15px 0 0;
  }
  .articleList__item .botItem .info {
    margin: 22px 0 0;
  }
  .articleList__item .botItem .info .tag__item {
    margin-right: 10px;
  }
  .articleList__item .botItem .info .tag__item > * {
    font-size: 1.2rem;
  }
  .articleList__item .botItem .info .view {
    font-size: 1.5rem;
  }
  .articleList__item .botItem .info .view::before {
    width: 15px;
    height: 16px;
  }
  .articleList__item + .articleList__item {
    margin: 17px 0 0;
  }
  /* =================================
    articleDetail
  ================================= */
  .articleDetail .breadcrumb {
    padding: 20px 5% 20px;
  }
  /* =================================
    singlePage
  ================================= */
  .singlePage .tocBox {
    margin: 35px 0 45px -5%;
    width: calc(100% + 10%);
  }
  .singlePage .tocBox__ttl {
    font-size: 2.5rem;
  }
  .singlePage .tocBox__ttl::before {
    width: 24px;
    height: 14px;
    margin: 0 7px 4px 0;
  }
  .singlePage .tocBox .tocList {
    margin: 25px auto 0;
  }
  .singlePage .tocBox .tocList__item a {
    font-size: 1.5rem;
    padding: 12px 10% 12px 13%;
  }
  .singlePage .tocBox .tocList__item a::before {
    left: 5%;
  }
  .singlePage .tocBox .tocList__item a::after {
    right: 3%;
  }
  .singlePage .data {
    margin: -63px 0 0;
    padding: 0 5%;
    display: block;
  }
  .singlePage .data .date {
    width: 86px;
    height: 86px;
  }
  .singlePage .data .date .num {
    font-size: 2rem;
  }
  .singlePage .data .info {
    margin: 10px 0 0;
    width: 100%;
    display: block;
  }
  .singlePage .data .info .tag__item {
    margin-right: 11px;
  }
  .singlePage .data .info .tag__item > * {
    font-size: 1.4rem;
  }
  .singlePage .data .info .social {
    margin: 10px 0 0;
  }
  .singlePage .data .info .social__item .icon {
    width: 18px;
    margin: 0 5px 5px 0;
  }
  .singlePage .data .info .social__item .count {
    font-size: 1.5rem;
  }
  .singlePage .data .info .social__item + .social__item {
    margin-left: 15px;
  }
  .singlePage__in {
    padding: 20px 5% 40px;
  }
  .singlePage__in > .caption {
    font-size: 1.5rem;
    margin: 10px 0 0 -2%;
    width: calc(100% + 4%);
  }
  .singlePage__in > h1 {
    font-size: 2.5rem;
    margin: 15px 0 20px -2%;
    width: calc(100% + 4%);
  }
  .singlePage__in > h2 {
    font-size: 2.2rem;
    padding: 15px 5%;
    margin: 40px 0 30px;
  }
  .singlePage__in > h3 {
    font-size: 2rem;
    border-left: 5px solid #267ea7;
    padding: 2px 4% 4px;
    margin: 35px 0 25px;
  }
  .singlePage__in > h3::after {
    left: -5px;
    bottom: -5px;
  }
  .singlePage__in > h4 {
    font-size: 1.8rem;
    padding: 10px 4%;
    margin: 30px 0 20px;
  }
  .singlePage__in > h5 {
    font-size: 1.5rem;
    margin: 30px 0 20px;
  }
  .singlePage__in > p {
    font-size: 1.5rem;
  }
  .singlePage__in > p + p {
    margin-top: 25px;
  }
  .singlePage__in > img {
    display: block;
    margin: 0 auto 30px;
  }
  .singlePage__in > figure {
    margin: 0 auto 30px;
  }
  .singlePage__in > figure figcaption {
    font-size: 1.2rem;
    margin: 15px 0 0;
  }
  .singlePage__in > table {
    margin: 35px 0 25px;
  }
  .singlePage__in > ul:not([class]) {
    padding: 15px 5%;
    margin: 25px auto;
  }
  .singlePage__in > ul:not([class]) li {
    font-size: 1.5rem;
  }
  .singlePage__in > ul:not([class]) li + li {
    margin: 10px 0 0;
  }
  .singlePage__in > ol:not([class]) {
    padding: 15px 5%;
    margin: 25px auto;
    background: #f8f9fa;
  }
  .singlePage__in > ol:not([class]) li {
    font-size: 1.5rem;
  }
  .singlePage__in > ol:not([class]) li::before {
    font-size: 1.5rem;
  }
  .singlePage__in > ol:not([class]) li + li {
    margin: 10px 0 0;
  }
  .singlePage__in > blockquote {
    padding: 5px 12px 5px 68px;
  }
  .singlePage__in > blockquote::before {
    left: 5%;
    width: 35px;
    height: 35px;
    font-size: 3.8rem;
    line-height: 1.3;
  }
  .singlePage__in > blockquote p {
    font-size: 1.4rem;
    line-height: 1.65;
  }
  .singlePage .pushBox {
    border: 4px solid #ddf3fe;
    padding: 30px 5%;
    margin: 30px auto 40px;
    font-size: 1.5rem;
  }
  .singlePage .pushBox .label {
    font-size: 1.2rem;
    padding: 6px 10px;
  }
  .singlePage .pushBox__cont {
    display: block;
  }
  .singlePage .pushBox__cont .topItem {
    width: 100%;
    margin: 0 0 18px;
  }
  .singlePage .pushBox__cont .botItem {
    width: 100%;
  }
  .singlePage .pushBox__cont .botItem .ttl {
    font-size: 1.5rem;
  }
  .singlePage .pushBox__cont .botItem .text {
    margin: 10px 0 0;
  }
  .singlePage .col2Box {
    display: block;
    margin: 25px auto 40px;
  }
  .singlePage .col2Box .topItem {
    width: 100%;
  }
  .singlePage .col2Box .botItem {
    width: 100%;
    font-size: 1.5rem;
    padding: 5%;
  }
  .relatedBox {
    padding: 40px 5%;
  }
  .relatedBox .ttl--related {
    text-align: center;
    margin: 0 0 35px;
  }
  .relatedBox .ttl--related__ttl {
    font-size: 2.5rem;
  }
  .relatedBox .ttl--related__ttl::before {
    width: 18px;
    height: 21px;
    margin: 0 5px px 0;
  }
  .relatedBox .ttl--related__text {
    font-size: 1.3rem;
    margin: 10px 0 0;
  }
}

@media screen and (max-width: 600px) {
  .topPage .topSec.media {
    background: url(../img/bg_media_sp.jpg) repeat-y center top;
    background-size: contain;
  }
}
